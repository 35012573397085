import { DeleteIcon } from "@chakra-ui/icons";
import { Flex, Img, Text, Tooltip } from "@chakra-ui/react";
import { IProject } from "interfaces/IProject";
import { useHistory } from "react-router-dom";

interface ICardProject {
  project: IProject;
  setProjectsSelected: (value: React.SetStateAction<IProject>) => void;
  handleModalToChangeProjectState: () => void;
}

export const CardProject: React.FC<ICardProject> = ({
  project,
  setProjectsSelected,
  handleModalToChangeProjectState,
}) => {
  const history = useHistory();
  return (
    <Flex
      w="100%"
      bg="grayBg"
      p="3"
      px="6"
      align={"center"}
      justify={"space-between"}
      _hover={{
        opacity: 0.8,
      }}
      borderRadius={"8px"}
      cursor={"pointer"}
      fontSize={["sm", "sm", "md"]}
      overflowY={"hidden"}
      minH="60px"
      gap="4"
    >
      <Flex
        onClick={() => history.push(`/projects/create/${project.id}`)}
        width={"100%"}
        align={"center"}
        justify={"space-between"}
        mr="6"
        gap="4"
        pb="2"
      >
        <Flex flexDir={"column"}>
          <Text color="grayLight" fontSize={["xs"]}>
            Nome do projeto
          </Text>
          <Tooltip
            label={`Nome do projeto: ${project.name}`}
            aria-label="A tooltip"
            bg="black"
            placement="top"
          >
            <Text maxW="200px" minW="200px" isTruncated fontSize={"sm"}>
              {project.name}
            </Text>
          </Tooltip>
        </Flex>

        <Flex flexDir={"column"}>
          <Text color="grayLight" fontSize={["xs"]}>
            Status
          </Text>
          <Text fontSize={"sm"} isTruncated minW="100px" maxW="100px">
            {project.isActive ? "Ativado" : "Desativado"}
          </Text>
        </Flex>

        <Flex flexDir={"column"}>
          <Text color="grayLight" fontSize={["xs"]}>
            Cliente
          </Text>

          <Tooltip
            label={`Cliente: ${project.customer?.name}`}
            aria-label="A tooltip"
            bg="black"
            placement="top"
          >
            <Text isTruncated minW="100px" maxW="100px" fontSize={"sm"}>
              {project.customer?.name}
            </Text>
          </Tooltip>
        </Flex>
      </Flex>

      <Flex
        color={project.isActive ? "red" : "orange2"}
        align={"center"}
        justify={"center"}
        gap="2"
        pt="2"
        _hover={{
          color: project.isActive ? "#aa2f2f" : "orange",
        }}
        onClick={() => {
          setProjectsSelected(project);
          handleModalToChangeProjectState();
        }}
        fontSize={"sm"}
      >
        <Img as={DeleteIcon} fontSize={["sm", "sm"]} />
        <Text> {project.isActive ? "Desativar" : "Reativar"}</Text>
      </Flex>
    </Flex>
  );
};
