import {
  ITimeAndFinancialControl,
  IProjectPageProps,
} from "interfaces/IProject";
import InputWithLabel from "components/Form/input/InputWithLabel";
import DateInput from "components/DateComponents/DatePicker";

import { useCallback, useEffect, useState } from "react";
import { Flex, Text, useToast } from "@chakra-ui/react";
import CustomButtom from "components/Form/Button";
import { useHistory } from "react-router-dom";
import { useAuth } from "modules/auth/hooks";
import { checkPermission } from "helpers/checkPermission";

export const HoursControl: React.FC<IProjectPageProps> = ({
  projectId,
  project,
  handleSaveProject,
}) => {
  const data = useAuth();

  const [timeAndFiancialData, setFormData] = useState<ITimeAndFinancialControl>(
    {
      soldHours: undefined,
      startedAt: undefined,
      endedAt: undefined,
      expectedStart: undefined,
      expectedEnd: undefined,
      learningSummary: undefined,
      successCriteria: undefined,
    },
  );

  const [isLoadingProjects, setIsLoadingProjects] = useState(false);
  const toast = useToast();
  const history = useHistory();

  const handleChangeInput = (event) => {
    const { name, value } = event.target;

    if (name.includes("soldHours")) {
      setFormData({ ...timeAndFiancialData, [name]: Number(value) });
      return;
    }

    setFormData({ ...timeAndFiancialData, [name]: value });
  };

  const handleUpdateTimeAndFiancialData = useCallback(
    async (timeFinancialData: ITimeAndFinancialControl) => {
      const hasPermission = checkPermission({
        permission: ["admin"],
        userRole: data.data.encryptedLoggedUserRoles,
      });

      if (!hasPermission) {
        toast({
          title: "Permissão Negada.",
          description: "Usuário não tem permissão para executar essa ação.",
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
        return undefined;
      }

      setIsLoadingProjects(true);
      await handleSaveProject({
        ...project,
        ...timeFinancialData,
      });

      setIsLoadingProjects(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.data?.email, history, projectId, toast, timeAndFiancialData],
  );

  useEffect(() => {
    setFormData(project);
  }, [project]);

  return (
    <Flex
      flexDir={["column", "column", "row"]}
      maxH={[
        "calc(100vh - 240px)",
        "calc(100vh - 240px)",
        "calc(100vh - 360px)",
      ]}
      overflow={"auto"}
      mt="5"
      gap="12"
      pr="2"
      pt="2"
      pb="5"
    >
      <Flex
        flexDir={"column"}
        flex="1"
        justify={"space-between"}
        gap="8"
        maxH="calc(100vh - 300px)"
      >
        <Flex gap="4">
          <InputWithLabel
            label="Horas vendidas"
            name={"soldHours"}
            type="number"
            value={timeAndFiancialData?.soldHours}
            onChange={(e) => handleChangeInput(e)}
          />
        </Flex>
        <Flex flexDir={"column"} gap="4">
          <Text color="green1" fontSize={["md", "lg"]}>
            Datas contratadas
          </Text>
          <Flex gap="4" align={"center"} justify={"space-between"}>
            <DateInput
              value={timeAndFiancialData.expectedStart}
              onChange={(day) => {
                setFormData({
                  ...timeAndFiancialData,
                  expectedStart: day,
                });
              }}
              label="Data prevista de inicio"
              name={"expectedStart"}
            />

            <DateInput
              value={timeAndFiancialData.expectedEnd}
              onChange={(day) => {
                setFormData({
                  ...timeAndFiancialData,
                  expectedEnd: day,
                });
              }}
              label="Data prevista de termino"
            />
          </Flex>
        </Flex>

        <Flex flexDir={"column"} gap="4">
          <Text color="green1" fontSize={["md", "lg"]}>
            Datas de projeto
          </Text>
          <Flex gap="4" align={"center"} justify={"space-between"}>
            <DateInput
              value={timeAndFiancialData.startedAt}
              label="Data de admissão"
              name={"startAt"}
              onChange={(day) => {
                setFormData({
                  ...timeAndFiancialData,
                  startedAt: day,
                });
              }}
            />

            <DateInput
              value={timeAndFiancialData?.endedAt}
              onChange={(e) => {
                setFormData({
                  ...timeAndFiancialData,
                  endedAt: e,
                });
              }}
              name={"endAt"}
              label="Data término"
            />
          </Flex>
        </Flex>
      </Flex>

      <Flex flexDir={"column"} flex="1" gap="8">
        <Text color="green1" fontSize={["md", "lg"]}>
          Indicadores e aprendizado
        </Text>
        <Flex flexDir={"column"} h="100%" gap="12">
          <InputWithLabel
            label="Critério de sucesso"
            name={"successCriteria"}
            value={timeAndFiancialData.successCriteria}
            onChange={(e) => handleChangeInput(e)}
          />
          <InputWithLabel
            label="Resumo de Aprendizados"
            name={"learningSummary"}
            value={timeAndFiancialData.learningSummary}
            onChange={(e) => handleChangeInput(e)}
          />
        </Flex>

        <Flex align={"center"} justify={"flex-end"}>
          <CustomButtom
            primaryText="Cancelar"
            py="6"
            px="8"
            onClick={() => {
              history.push("/projects");
            }}
          />
          <CustomButtom
            primaryText="Salvar dados do projeto"
            bg="orange2"
            color="white"
            _hover={{
              opacity: 0.8,
            }}
            py="6"
            px="10"
            isLoading={isLoadingProjects}
            onClick={async () =>
              await handleUpdateTimeAndFiancialData(timeAndFiancialData)
            }
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
