import { Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import { ModalAddTask } from "../Components/taskDataModal";
import { useCallback, useEffect, useState } from "react";
import { CardTask } from "../Components/taskCardToList";
import { IProjectPageProps } from "interfaces/IProject";
import { TaskService } from "services/TaskService";
import { useHistory } from "react-router-dom";
import ModalWrapper from "components/Modal";
import { ITask } from "interfaces/ITask";
import { IUser } from "interfaces/IUser";
import { UserService } from "services/UserService";
import Dropdown from "components/Form/Select";
import { handleOrderValues } from "helpers/sortArray";
import InputWithLabel from "components/Form/input/InputWithLabel";

export const Tasks: React.FC<IProjectPageProps> = ({ projectId, project }) => {
  const [modalTaskData, setModalTaskData] = useState(false);
  const [modalToDeleteTask, setModalToDeleteTask] = useState(false);
  const [isLoadingTasks, setIsLoadingTasks] = useState(false);

  const toast = useToast();
  const history = useHistory();
  const [tasks, setTasks] = useState<ITask[]>([]);
  const [tasksFiltered, setTasksFiltered] = useState<ITask[]>([]);
  const [usersInProject, setUsersInProject] = useState<IUser[]>([]);
  const [isFilteredByisActive, setIsFilteredByIsActive] = useState<
    boolean | undefined
  >(true);

  const [selectedTask, setSelectedTask] = useState<ITask>({
    id: "",
    name: "",
    manager: {
      id: "",
      name: "",
      email: "",
    },
    description: "",
    expectedStart: undefined,
    expectedEnd: undefined,
    expectedHours: undefined,
    assignedToUsers: [],
    isActive: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  });

  const taskService = new TaskService(projectId);
  const userService = new UserService();

  const applyFilters = (filters: any, data?: any) => {
    const projectsFilter = data?.length > 0 ? data : tasks;

    const projectsFiltered = projectsFilter.filter((item) => {
      const statusMatches =
        filters.isActive === undefined || item.isActive === filters.isActive;

      const nameMatches =
        !filters.name ||
        !item.name ||
        item.name.toLowerCase().includes(filters.name.toLowerCase());

      return statusMatches && nameMatches;
    });

    if (filters.isActive === undefined) {
      return setTasksFiltered(handleOrderValues(tasks, "name"));
    }

    setTasksFiltered(handleOrderValues(projectsFiltered, "name"));
  };

  const handleFilterByIsName = (name: any) => {
    applyFilters({
      isActive: isFilteredByisActive,
      name: name,
    });
  };

  const handleFilterByIsActive = (isActive: any) => {
    applyFilters({
      isActive: isActive,
    });
  };

  const handleSaveTaskData = useCallback(
    async (task: ITask) => {
      setIsLoadingTasks(true);
      let response;
      if (
        task.name == undefined ||
        task.name == "" ||
        task.manager == undefined ||
        task.manager.id == ""
      ) {
        toast({
          title: "Ocorreu um erro.",
          description: "Alguns campos estão vazios",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsLoadingTasks(false);
        return;
      }

      if (task.id) response = await taskService.updateTask(task);
      else response = await taskService.createTask(task);
      if (response?.error) {
        toast({
          title: "Ocorreu um erro.",
          description: "Erro ao salvar a task",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Dados atualizados com sucesso.",
          description: "Task foi salva com sucesso!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        handleGetProjectTasks(isFilteredByisActive);
        setIsLoadingTasks(false);
        setModalTaskData(false);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tasks, history, projectId, toast, isFilteredByisActive],
  );

  const handleDeleteTask = useCallback(
    async (task) => {
      if (task) {
        setModalToDeleteTask(true);
        await taskService.toggleIsActiveTask(task, task.id);
        handleGetProjectTasks(isFilteredByisActive);
        setModalToDeleteTask(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, selectedTask, toast, modalToDeleteTask, isFilteredByisActive],
  );

  const handleSelectTask = useCallback((task: ITask | undefined) => {
    if (task == undefined) {
      setSelectedTask({
        id: "",
        name: "",
        manager: {
          id: "",
          name: "",
          email: "",
        },
        assignedToUsers: [],
        isActive: true,
        createdAt: new Date(),
        updatedAt: new Date(),
      });
    } else {
      setSelectedTask(task);
    }
    setModalTaskData(true);
  }, []);

  const handleGetUsersInProject = useCallback(async (emailUsers?: string[]) => {
    const usersRequested: IUser[] = [];
    if (emailUsers) {
      emailUsers.map(async (u) => {
        const userFinded = await userService.getUserByEmail(
          u + "@hvarconsulting.com.br",
        );
        if (userFinded) {
          usersRequested.push(userFinded);
        }
      });
    }
    setUsersInProject(usersRequested);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetProjectTasks = useCallback(
    async (isActive?: boolean) => {
      if (projectId) {
        setIsLoadingTasks(true);
        const response = await taskService.getProjectTasks();

        if (response.length > 0) {
          applyFilters(
            {
              isActive: isActive !== undefined ? isActive : true,
            },
            response,
          );
          setTasks(response);
          setIsLoadingTasks(false);
        }
        setIsLoadingTasks(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [projectId],
  );

  useEffect(() => {
    handleGetProjectTasks(isFilteredByisActive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleGetUsersInProject(project.assignedToUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  return (
    <>
      <Flex p="5" flexDir={"column"}>
        <Flex gap="3" justify={"space-between"} align={"center"}>
          <Flex align={"center"} justify={"center"} gap="2">
            <Text color="green1" fontSize={["md", "lg"]}>
              Tasks do projeto
            </Text>
          </Flex>

          <Flex align={"center"} justify={"center"} gap="8" minW="20%">
            <Flex w="100%" align={"center"} justify={"center"} gap={5}>
              <InputWithLabel
                label="Pesquisar"
                onChange={(e) => handleFilterByIsName(e.target.value)}
              />
            </Flex>
            <Dropdown
              minW={["100%", "100%", "100%"]}
              maxW={["100%", "100%", "100%"]}
              fontSize={["sm"]}
              onChange={(e: any) => {
                setIsFilteredByIsActive(
                  e.target.value === "false"
                    ? false
                    : e.target.value === ""
                      ? undefined
                      : Boolean(e.target.value),
                );
                handleFilterByIsActive(
                  e.target.value === "false"
                    ? false
                    : e.target.value === ""
                      ? undefined
                      : Boolean(e.target.value),
                );
              }}
            >
              <option value="true">Tarefas ativas </option>
              <option value="false">Tarefas desativadas </option>
            </Dropdown>
            <Text
              fontSize={["xs", "sm"]}
              cursor={"pointer"}
              _hover={{
                opacity: 0.7,
              }}
              whiteSpace={"nowrap"}
              onClick={() => {
                handleSelectTask(undefined);
              }}
            >
              Adicionar nova +
            </Text>
          </Flex>
        </Flex>

        <Flex
          maxH="calc(100vh - 430px)"
          overflow={"auto"}
          mt="5"
          gap="4"
          flexDir={"column"}
          pr="2"
        >
          {isLoadingTasks ? (
            <Flex
              align={"center"}
              justify={"center"}
              h={"100%"}
              minH="calc(100vh - 500px)"
              w="100%"
            >
              <Spinner />
            </Flex>
          ) : (
            <>
              {tasksFiltered.map((task, index) => {
                return (
                  <CardTask
                    key={index}
                    task={task}
                    handleSelectTask={() => handleSelectTask(task)}
                    handleDeleteTask={() => handleDeleteTask(task)}
                  />
                );
              })}
            </>
          )}

          <ModalWrapper
            isOpen={modalTaskData}
            onClose={() => {
              setModalTaskData(!modalTaskData);
            }}
            isShowCloseButton={true}
            overflow="auto"
            minW={["200px", "500px", "800px"]}
            h="650px"
          >
            <ModalAddTask
              task={selectedTask}
              isLoadingTasks={isLoadingTasks}
              usersInProject={usersInProject}
              setSelectedTask={setSelectedTask}
              handleSaveTaskData={handleSaveTaskData}
              setModalTaskData={setModalTaskData}
            />
          </ModalWrapper>
        </Flex>
      </Flex>
    </>
  );
};
