import { Flex, ModalHeader, Text } from "@chakra-ui/react";
import CustomButtom from "components/Form/Button";

interface IModalConfirmDelete {
  textModal: string;
  loading?: boolean;
  handleClose: () => void;
  handleDelete: () => Promise<void>;
}

export const ModalConfirmDelete: React.FC<IModalConfirmDelete> = ({
  handleDelete,
  handleClose,
  textModal,
  loading,
}) => {
  return (
    <>
      {" "}
      <ModalHeader>Tem certeza?</ModalHeader>
      <Flex px="5" pb={4} gap="4" flexDir={"column"}>
        <Text fontSize={"sm"}>{textModal}</Text>

        <Flex align={"center"} justify={"space-between"}>
          <CustomButtom
            primaryText="Cancelar"
            py="4"
            px="6"
            onClick={() => {
              handleClose();
            }}
          />
          <CustomButtom
            primaryText="Confirmar"
            bg="orange2"
            color="white"
            _hover={{
              opacity: 0.8,
            }}
            py="4"
            px="8"
            isLoading={loading}
            onClick={async () => await handleDelete()}
          />
        </Flex>
      </Flex>
    </>
  );
};
