import { Switch, Route, useHistory } from "react-router-dom";

import { Helmet } from "react-helmet";

import { routes } from "./routes";
import { Suspense, useEffect } from "react";

import LoadingPage from "components/LoadingPage";

import { useAuth } from "modules/auth/hooks";
import { checkPermission } from "helpers/checkPermission";

const Router: React.FC = () => {
  const history = useHistory();
  const url = window.location.pathname;
  const user = useAuth();

  useEffect(() => {
    const checkUserCanAccess = routes.find((item) => item.path === url);

    if (checkUserCanAccess) {
      const findPermission = checkPermission({
        permission: checkUserCanAccess?.role,
        userRole: user?.data?.encryptedLoggedUserRoles,
      });

      if (!findPermission) {
        if (url !== "/") history.push("/timesheet/note");
      }
    }
  }, [history, url, user?.data?.encryptedLoggedUserRoles]);

  return (
    <Switch>
      {routes.map((item) => {
        const Component = item.component;
        const Layout = item.layout;
        return (
          <Route key={item.path} path={item.path} exact>
            <Helmet>
              <title>{item.title}</title>
            </Helmet>
            <Layout isPublic={item.isPublic} {...item}>
              <Suspense fallback={<LoadingPage />}>
                <Component />
              </Suspense>
            </Layout>
          </Route>
        );
      })}
    </Switch>
  );
};

export default Router;
