import { TabList, Tab, Flex, Text, Img } from "@chakra-ui/react";
import { TabListContainerProps } from "../types";
import { CheckCircleIcon, SmallCloseIcon } from "@chakra-ui/icons";

const TabListContainer: React.FC<TabListContainerProps> = ({
  tabs,
  onTabChange,
  activeTabIndex,
  showExcludeUser,
  handleOpenModal,
  status,
}) => {
  return (
    <Flex
      overflowX="auto"
      overflowY={"hidden"}
      py="2"
      px="4"
      justify={"space-between"}
      align={"center"}
    >
      <TabList px="2" borderBottom="none" display="flex">
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            onClick={() => onTabChange(index)}
            borderBottom={
              index === activeTabIndex ? "2px solid orange2" : "2px solid"
            }
            borderColor={"grayLight"}
            color={index === activeTabIndex ? "orange2" : "grayLight"}
            _selected={{
              borderBottomColor: "orange2",
              color: "blue.500",
              borderBottom: "3px solid",
            }}
            _focus={{ boxShadow: "none" }}
            px="3"
            whiteSpace="nowrap" // Evita que as abas quebrem para uma nova linha
            flexShrink={0} // Evita que as abas tentem ocupar todo o espaço disponível
          >
            {tab.label}
          </Tab>
        ))}
      </TabList>

      {showExcludeUser && (
        <Flex
          align={"center"}
          justify={"center"}
          gap="1"
          cursor={"pointer"}
          _hover={{
            opacity: 0.7,
          }}
          onClick={handleOpenModal}
        >
          <Flex
            bg={status ? "red" : "white"}
            color={status ? "white" : "orange2"}
            borderRadius={"100%"}
          >
            <Img as={status ? SmallCloseIcon : CheckCircleIcon} zIndex={9} />
          </Flex>
          <Text
            color={status ? "red" : "orange2"}
            transition="opacity 0.15s ease-in-out"
          >
            {status ? "Desligar" : "Reativar"} colaborador
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default TabListContainer;
