export const handleOrderValues = (array: any[], field: any) => {
  const orderValues = array.sort((a, b) => {
    let result = 0;

    if (a[field].toLowerCase() < b[field].toLowerCase()) result = -1;
    if (a[field].toLowerCase() > b[field].toLowerCase()) result = 1;

    return result;
  });

  return orderValues;
};
