import { Flex, Img, Text, Tooltip } from "@chakra-ui/react";
import { useState } from "react";

type DocType = "nf" | "pf_id" | "pj_id" | "other";

import { DeleteIcon } from "@chakra-ui/icons";
import { IDocumentData, IimageBlob } from "interfaces/IDocuments";

interface IUploadSquareButtonUserProps {
  setImageBlob?: React.Dispatch<React.SetStateAction<Blob | null>>;
  imgUrl?: string;
  loading?: boolean;
  imageBlob?: IimageBlob | null;
  label?: string;
  handleUploadDocument?: (
    document: IDocumentData,
    blob: IimageBlob | null,
    title?: string,
    type?: DocType,
  ) => Promise<void>;
  documentData?: IDocumentData;

  handleSetDocumentToDelete: (
    userId?: string,
    docBucketId?: string,
    documentId?: string,
  ) => void;

  selectedDocument?: IDocumentData;
  Upload?: any;
}

export const UploadSquareButtonUser: React.FC<IUploadSquareButtonUserProps> = ({
  setImageBlob,

  Upload,
  label = "Logotipo",
  handleUploadDocument,
  documentData,
  selectedDocument,
  handleSetDocumentToDelete,
}) => {
  const [previewUrl, setPreviewUrl] = useState<string | undefined>();

  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setImageBlob && setImageBlob(file);
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setPreviewUrl(fileReader.result as string);
      };
      fileReader.readAsDataURL(file);
      handleUploadDocument &&
        (await handleUploadDocument(
          documentData ? documentData : ({} as IDocumentData),
          file,
          label,
          label === "Cartão CNPJ"
            ? "pj_id"
            : label === "Identidade ou CNH"
              ? "pf_id"
              : "other",
        ));
    }
  };

  return (
    <>
      {" "}
      <label>
        {!selectedDocument?.uri && (
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleFileInputChange}
          />
        )}
        <Flex
          as="div"
          border={"1px solid"}
          borderStyle={"dashed"}
          height={"160px"}
          width={"170px"}
          flexDir={"column"}
          align={"center"}
          justify={"space-evenly"}
          borderRadius={"8px"}
          cursor={"pointer"}
          position="relative"
        >
          {selectedDocument?.uri && (
            <Tooltip
              label={"Deletar documento?"}
              aria-label="A tooltip"
              bg="black"
              placement="top"
            >
              <Img
                as={DeleteIcon}
                ml="auto"
                mr="2"
                fontSize={"20px"}
                color="red"
                _hover={{
                  opacity: 0.7,
                }}
                onClick={() => {
                  handleSetDocumentToDelete(
                    selectedDocument?.owner.id || "",

                    selectedDocument?.uri.split("?alt")[0]?.split("%2F")[
                      selectedDocument?.uri.split("?alt")[0]?.split("%2F")
                        .length - 1
                    ] || "",
                    selectedDocument?.id || "",
                  );
                }}
                transition="opacity 0.15s ease-in-out"
              />
            </Tooltip>
          )}
          <a
            href={selectedDocument?.uri}
            download
            target="_blank"
            rel="noreferrer"
          >
            <Tooltip
              label={"Baixar documento ?"}
              aria-label="A tooltip"
              bg="black"
              placement="top"
            >
              <Img
                height={"auto"}
                width={"100%"}
                maxH={"60px"}
                src={previewUrl || Upload}
                alt="Logo"
                top={previewUrl ? 0 : 70}
                objectFit={"contain"}
              />
            </Tooltip>
          </a>

          <Tooltip
            label={documentData?.name || label}
            aria-label="A tooltip"
            bg="black"
            placement="top"
          >
            <Text isTruncated maxW={160} mt="20px" fontSize={"sm"}>
              {selectedDocument?.name || documentData?.name || label}
            </Text>
          </Tooltip>
        </Flex>
      </label>
    </>
  );
};
