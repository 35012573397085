import { Checkbox, Flex } from "@chakra-ui/react";

type CustomCheckBox = {
  [key: string]: any;
};

const CustomCheckBox: React.FC<CustomCheckBox> = ({ ...rest }) => {
  const CustomIcon = (props: any) => {
    const { isChecked } = props;

    return (
      <>
        {isChecked ? (
          <Flex
            bg="orange2"
            h="70%"
            w="70%"
            align={"center"}
            justify={"center"}
          />
        ) : null}
      </>
    );
  };

  return (
    <>
      <Checkbox
        size="md"
        borderColor={"grayLight"}
        bg="white"
        icon={<CustomIcon />}
        {...rest}
      />
    </>
  );
};

export default CustomCheckBox;
