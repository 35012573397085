import { Flex, Img, Spinner, Text, Tooltip } from "@chakra-ui/react";
import { useState } from "react";
import pdfImg from "assets/img/pdfImg.png";
import csvImg from "assets/img/csvImg.png";
import docxImg from "assets/img/docxIcon.png";
import xmlImg from "assets/img/xmlIcon.png";

import { DeleteIcon } from "@chakra-ui/icons";
import { IDocumentData, IimageBlob } from "interfaces/IDocuments";

interface IUploadButton {
  setImageBlob: React.Dispatch<React.SetStateAction<Blob | null>>;
  imgUrl?: string;
  loading?: boolean;
  imageBlob: IimageBlob | null;
  label?: string;
  handleUploadDocument: (
    document: IDocumentData,
    blob: IimageBlob | null,
    title?: string,
  ) => Promise<void>;
  documentData: IDocumentData;

  handleSetDocumentToDelete: (
    userId?: string,
    docBucketId?: string,
    documentId?: string,
  ) => void;
  selectedDocument?: IDocumentData;

  Upload: any;
}

export const UploadButtonUser: React.FC<IUploadButton> = ({
  setImageBlob,

  loading,
  imageBlob,
  Upload,
  label = "Logotipo",
  handleUploadDocument,
  documentData,
  selectedDocument,
  handleSetDocumentToDelete,
}) => {
  const [previewUrl, setPreviewUrl] = useState<string | undefined>();

  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setImageBlob(file);
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setPreviewUrl(fileReader.result as string);
      };
      fileReader.readAsDataURL(file);

      await handleUploadDocument(documentData, file, label);
    }
  };

  return (
    <>
      <label>
        {!selectedDocument?.uri && (
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleFileInputChange}
          />
        )}
        <Flex
          as="div"
          border="1px solid"
          borderStyle="dashed"
          width="100%"
          px="20px"
          py="6px"
          flexDir="column"
          align="center"
          justify="center"
          borderRadius="4px"
          cursor="pointer"
        >
          {loading ? (
            <Flex w="100%" h="100%">
              <Spinner />
            </Flex>
          ) : (
            <>
              <Flex align={"center"} justify={"space-between"}>
                <a
                  href={selectedDocument?.uri}
                  download
                  target="_blank"
                  rel="noreferrer"
                >
                  <Flex align={"center"} justify={"space-between"}>
                    <Img
                      maxH="35px"
                      maxW="150px"
                      minW={"80px"}
                      mr="2"
                      src={
                        selectedDocument?.type?.includes("pdf")
                          ? pdfImg
                          : selectedDocument?.type?.includes("csv") ||
                              selectedDocument?.type?.includes("xls")
                            ? csvImg
                            : selectedDocument?.type?.includes("docx")
                              ? docxImg
                              : selectedDocument?.type?.includes("xml")
                                ? xmlImg
                                : Upload
                      }
                      alt="Logo"
                      objectFit="contain"
                    />
                    <Tooltip
                      label={
                        (!previewUrl && !imageBlob && label) || imageBlob?.name
                      }
                      aria-label="A tooltip"
                      bg="black"
                      placement="top"
                    >
                      <Text isTruncated maxW={200} fontSize={"sm"}>
                        {(!previewUrl && !imageBlob?.name && label) ||
                          imageBlob?.name}
                      </Text>
                    </Tooltip>
                  </Flex>
                </a>

                {selectedDocument?.uri && (
                  <Tooltip
                    label={"Deletar documento?"}
                    aria-label="A tooltip"
                    bg="black"
                    placement="top"
                  >
                    <Img
                      top={0}
                      ml="3"
                      as={DeleteIcon}
                      fontSize={"20px"}
                      color="red"
                      _hover={{
                        opacity: 0.7,
                      }}
                      onClick={() => {
                        handleSetDocumentToDelete(
                          selectedDocument?.owner.id || "",
                          selectedDocument?.uri.split("?alt")[0]?.split("%2F")[
                            selectedDocument?.uri.split("?alt")[0]?.split("%2F")
                              .length - 1
                          ] || "",
                          selectedDocument?.id || "",
                        );
                        setImageBlob({} as IimageBlob);
                        setPreviewUrl("");
                      }}
                      transition="opacity 0.15s ease-in-out"
                    />
                  </Tooltip>
                )}
              </Flex>
            </>
          )}
        </Flex>
      </label>
    </>
  );
};
