import { OAuthService } from "../firebase";
import { UserService } from "services/UserService";
import CryptoJS from "crypto-js";

const signIn = async (): Promise<any> => {
  const userService = new UserService();

  const auth = new OAuthService();
  const result = await auth.singIn();

  const email = result.user.email;
  const id = result.user.uid;
  const photo: string = result.user.photoURL;
  const accessToken = result.user.accessToken;

  const user = await userService.getUserById(id);

  const rolesOfLoggedUser = user?.roles || [];

  // Generate JWT
  const secretKey: string = process.env.REACT_APP_PROJECT_NAME ?? ""; // Use environment variable or a secure way to store your secret key
  const encryptedLoggedUserRoles = CryptoJS.AES.encrypt(
    JSON.stringify(rolesOfLoggedUser),
    secretKey,
  ).toString();

  if (user) {
    if (user.photoPath !== photo) {
      if (user.id) await userService.updateUserPhotoPath(user, photo);
    }

    localStorage.setItem("id", id || user?.id || "");
    localStorage.setItem("firstName", user?.firstName || "");
    localStorage.setItem("email", user?.email || "");
    localStorage.setItem("lastName", user?.lastName || "");
    localStorage.setItem("admissionDate", String(user?.admissionDate) || "");
    localStorage.setItem("creationDate", String(user?.creationDate) || "");
    localStorage.setItem("isActive", `${user.isActive}` || "");
    localStorage.setItem("sector", JSON.stringify(user?.sector) || "");
    localStorage.setItem("photoPath", photo || user.photoPath || "");
    localStorage.setItem("accessToken", accessToken || "");
    localStorage.setItem("isLogged", "true");
    localStorage.setItem("encryptedLoggedUserRoles", encryptedLoggedUserRoles);

    const responseData = {
      accessToken,
      email,
      firstName: user?.firstName,
      photoPath: photo || user.photoPath,
      isLogged: true,
      id: id,
      lastName: user?.lastName,
      admissionDate: String(user?.admissionDate),
      creationDate: String(user?.creationDate),
      isActive: user?.isActive,
      sector: user?.sector,
      encryptedLoggedUserRoles: encryptedLoggedUserRoles,
    };
    return responseData;
  }
};

export default signIn;
