import { DeleteIcon } from "@chakra-ui/icons";
import { Avatar, Flex, Img, Text } from "@chakra-ui/react";
import { IUser } from "interfaces/IUser";

interface ICollaboratorsCard {
  user: IUser;
  handleRemoveUser: (user: IUser) => void;
  layout?: "default" | "simple";
}
export const CollaboratorsCard: React.FC<ICollaboratorsCard> = ({
  user,
  handleRemoveUser,
  layout = "default",
}) => {
  return (
    <>
      <Flex
        bg="grayBg"
        align={"center"}
        justify={"space-between"}
        borderRadius={"8px"}
        p="3"
        w="100%"
        fontSize={["10px", "xs", "sm"]}
        flexWrap="wrap" // Permite que os itens se ajustem em várias linhas quando necessário
      >
        {layout == "simple" ? null : (
          <Avatar
            name={user.firstName || undefined}
            src={user.photoPath || undefined}
            size="md"
            mr="3"
          />
        )}
        <Flex flexDir={"column"} flex="1" minW="0">
          <Text
            color="GrayText"
            fontSize={["10px", "xs"]}
            isTruncated
            maxW={["100px", "200px", "300px", "300px"]}
            minW="200px"
          >
            Nome completo
          </Text>
          <Text isTruncated maxW={["100px", "200px", "300px", "300px"]}>
            {user.firstName} {user.lastName}
          </Text>
        </Flex>
        {layout == "simple" ? null : (
          <Flex flexDir={"column"} flex="1" minW="0">
            <Text
              fontSize={["10px", "xs"]}
              color="GrayText"
              isTruncated
              maxW={["80px", "150px", "200px", "200px"]}
            >
              Email
            </Text>
            <Text isTruncated maxW={["80px", "150px", "200px", "200px"]}>
              {user.email}
            </Text>
          </Flex>
        )}
        <Flex
          color="red"
          align={"center"}
          justify={"center"}
          gap="2"
          cursor={"pointer"}
          _hover={{
            opacity: 0.7,
          }}
          onClick={() => handleRemoveUser(user)}
        >
          <Img as={DeleteIcon} />
          <Text isTruncated>Excluir</Text>
        </Flex>
      </Flex>
    </>
  );
};
