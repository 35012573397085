import { IRole } from "interfaces/IUser";

interface CheckPermissionArgs {
  permission: string[];
  userRole: IRole;
}

export const checkPermission = ({
  permission,
  userRole,
}: CheckPermissionArgs): boolean => {
  const normalizedPermissions = permission.map((perm) => perm.toLowerCase());

  if (normalizedPermissions.includes("all")) {
    return true;
  }

  const allowed = Object.keys(userRole).some((key) => {
    if (key === "super_admin") return true;

    return normalizedPermissions.includes(key.toLowerCase());
  });

  return allowed;
};
