interface IAccount {
  bank: string;
  agency: string;
  account: string;
  pix: string;
}

interface IAddress {
  cep?: string;
  home_address?: string;
  city?: string;
  neighborhood?: string;
  municipality?: string;
}

interface IManager {
  id: string;
  name: string;
}

interface IRole {
  [key: string]: string;
}

interface ISector {
  id: string;
  name: string;
}

export class User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  photoPath?: string;
  admissionDate?: Date;
  bornDate?: Date;
  isActive: boolean;
  roles: IRole;
  sector: ISector;
  createdAt: Date;
  updatedAt: Date;
  jobRole?: string;
  manager?: IManager;
  cpf?: string;
  rg?: string;
  startDate?: Date;
  phoneNumberOptional?: string;
  personalEmail?: string;
  withHoldTax?: boolean;
  salary?: string;
  salaryType?: string;
  cnpj?: string;
  creationDate?: Date;
  account?: IAccount;
  address?: IAddress;
  benefit?: boolean;
  benefitStartAt?: Date;
  equipmentNeeded?: boolean;
  hiringRegime?: string;
  canReportUntil?: Date;

  constructor(
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    createdAt: Date,
    updatedAt: Date,
    isActive: boolean,
    roles: IRole,
    sector: ISector,
    admissionDate?: Date,
    bornDate?: Date,
    photoPath?: string,
    phoneNumber?: string,
    jobRole?: string,
    manager?: IManager,
    cpf?: string,
    rg?: string,
    startDate?: Date,
    phoneNumberOptional?: string,
    personalEmail?: string,
    withHoldTax?: boolean,
    salary?: string,
    salaryType?: string,
    cnpj?: string,
    hiringRegime?: string,
    creationDate?: Date,
    account?: IAccount,
    address?: IAddress,
    benefit?: boolean,
    benefitStartAt?: Date,
    equipmentNeeded?: boolean,
    canReportUntil?: Date,
  ) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.admissionDate = admissionDate;
    this.bornDate = bornDate;
    this.isActive = isActive;
    this.roles = roles;
    this.sector = sector;
    this.phoneNumber = phoneNumber;
    this.photoPath = photoPath;
    this.jobRole = jobRole;
    this.manager = manager;
    this.cpf = cpf;
    this.rg = rg;
    this.startDate = startDate;
    this.phoneNumberOptional = phoneNumberOptional;
    this.personalEmail = personalEmail;
    this.salary = salary;
    this.withHoldTax = withHoldTax;
    this.salaryType = salaryType;
    this.cnpj = cnpj;
    this.creationDate = creationDate;
    this.account = account;
    this.address = address;
    this.benefit = benefit;
    this.benefitStartAt = benefitStartAt;
    this.equipmentNeeded = equipmentNeeded;
    this.hiringRegime = hiringRegime;
    this.id = id;
    this.canReportUntil = canReportUntil;
  }

  static fromJson(json: any, id: string): User {
    return new User(
      id,
      json["first_name"],
      json["last_name"],
      json["email"],
      json["created_at"],
      json["updated_at"],
      json["is_active"],
      json["roles"],
      json["sector"],
      json["admission_date"],
      json["born_date"],
      json["photo_path"],
      json["phone_number"],
      json["job_role"],
      json["manager"],
      json["cpf"],
      json["rg"],
      json["start_date"],
      json["phone_number_optional"],
      json["personal_email"],
      json["withhold_tax"],
      json["salary"],
      json["salary_type"],
      json["cnpj"],
      json["hiring_regime"],
      json["creation_date"],
      json["account"],
      json["address"],
      json["benefit"],
      json["benefit_start_at"],
      json["equipment_needed"],
      json["can_report_until"],
    );
  }

  toJson(): any {
    let roles;
    let sector;
    let address;

    if (this.roles) {
      roles = this.roles;
    }

    if (this.address?.cep) {
      address = {
        cep: this.address?.cep,
        city: this.address?.city,
        home_address: this.address?.home_address,
        municipality: this.address?.municipality,
        neighborhood: this.address?.neighborhood,
      };
    }

    if (this.sector?.id) {
      sector = {
        id: this.sector.id,
        name: this.sector.name,
      };
    }

    const finalUser = {
      first_name: this.firstName,
      last_name: this.lastName,
      email: this.email,
      phone_number: this.phoneNumber,
      photo_path: this.photoPath,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
      admission_date: this.admissionDate,
      born_date: this.bornDate,
      is_active: this.isActive,
      roles: roles,
      sector: sector,
      job_role: this.jobRole,
      manager: this.manager,
      cpf: this.cpf,
      start_date: this.startDate,
      phone_number_optional: this.phoneNumberOptional,
      personal_email: this.personalEmail,
      withhold_tax: this.withHoldTax,
      salary: this.salary,
      rg: this.rg,
      salary_type: this.salaryType,
      cnpj: this.cnpj,
      hiring_regime: this.hiringRegime,
      creation_date: this.creationDate,
      account: this.account,
      address: address,
      benefit: this.benefit,
      benefit_start_at: this.benefitStartAt,
      equipment_needed: this.equipmentNeeded,
      can_report_until: this.canReportUntil,
    };

    const newObj = {};

    for (const key in finalUser) {
      if (finalUser[key] !== undefined) {
        newObj[key] = finalUser[key];
      }
    }

    return newObj;
  }
}
