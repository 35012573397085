import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentData,
  DocumentSnapshot,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  Query,
  query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  serverTimestamp,
  setDoc,
  SnapshotOptions,
  updateDoc,
  where,
  WhereFilterOp,
} from "firebase/firestore";

type Entity = {
  toJson: () => any;
};

interface QueryFilter {
  field: string;
  operator: WhereFilterOp;
  value: any;
}

type ObjectParser = (value: any, key: string, index: number) => any;
type FromJsonFunction<T> = (data: any, id: string) => T;

type ECollections =
  | "users"
  | "user_roles"
  | "sectors"
  | "timesheets"
  | "status_types"
  | "documents"
  | "customers"
  | "projects"
  | "project_types"
  | "journeys"
  | "additional_time_requests"
  | "time_reports"
  | `projects/${string}/tasks`
  | "logs";

// An abstract representation of Firestore data repository
export class FirestoreRepository<T extends Entity> {
  private collection: CollectionReference<T, DocumentData>;

  constructor(
    collectionName: ECollections,
    fromJson: FromJsonFunction<T>,
    firestore = getFirestore(),
  ) {
    this.collection = collection(firestore, collectionName).withConverter<T>({
      toFirestore(document: T): DocumentData {
        const data = document.toJson();
        data["updated_at"] = serverTimestamp();
        return data;
      },
      // TODO: fix, "toDate" is not finded
      fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions) {
        const data = FirestoreRepository.parseObject(
          snapshot.data(options),
          (v: any) => {
            if (typeof v?.toDate === "function") {
              return v.toDate();
            }
            return v;
          },
        );

        return fromJson(data, snapshot.id);
      },
    });
  }

  /**
   * Receives an object and parses its data with an `ObjectParser` function
   * @param obj Object to be parsed
   * @param fn Callback function used to parse object
   */
  static parseObject(obj: any, fn: ObjectParser): any {
    return Object.fromEntries(
      Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]),
    );
  }

  /**
   * Gets a single document by id
   * @param id Unique id of document
   * @returns
   */
  public async getDocument(
    id: string,
  ): Promise<DocumentSnapshot<T, DocumentData>> {
    const document = doc(
      this.collection.firestore,
      this.collection.path,
      id,
    ).withConverter(this.collection.converter!);

    return await getDoc(document);
  }

  /**
   * Gets all documents from the collection
   * @returns
   */
  public async getDocuments(): Promise<QuerySnapshot<T, DocumentData>> {
    return getDocs(this.collection);
  }

  /**
   * Query documents bades on filters
   * @param filters An array of filters to be used by the query
   * @param limitCount Limit of results to be returned by the query
   * @returns
   */
  public async queryDocuments(
    filters: QueryFilter[],
    limitCount?: number,
  ): Promise<QuerySnapshot<T, DocumentData>> {
    const parameters = filters.map((filter) =>
      where(filter.field, filter.operator, filter.value),
    );

    let queryInstance: Query<T, DocumentData>;

    if (limitCount) {
      queryInstance = query<T, DocumentData>(
        this.collection,
        ...parameters,
        limit(limitCount),
      );
    } else {
      queryInstance = query<T, DocumentData>(this.collection, ...parameters);
    }

    return await getDocs<T, DocumentData>(queryInstance);
  }

  /**
   * Creates a new document with an automatically assigned ID if none is specified.
   * @param data Data to be writen to database
   * @param id Unique id to be assigned to the document
   * @returns
   */
  public async createDocument(data: T, id?: string) {
    data["createdAt"] = serverTimestamp();

    if (id) {
      const document = doc(this.collection, id).withConverter(
        this.collection.converter!,
      );
      return setDoc(document, data);
    }

    return addDoc(this.collection, data);
  }

  /**
   * Updates a document on database
   * @param data Data to be writen to database
   * @param id Unique id of document to be updated
   * @returns
   */
  public async updateDocument(data: T, id: string): Promise<void> {
    const document = doc(
      this.collection.firestore,
      this.collection.path,
      id,
    ).withConverter(this.collection.converter!);

    return updateDoc(document, data.toJson());
  }

  /**
   * Updates is_active field in document on database
   * @param isActive value to be writen in document to database
   * @param id Unique id of document to be updated
   * @returns
   */
  public async updateIsActive(isActive: boolean, id: string): Promise<void> {
    const document = doc(
      this.collection.firestore,
      this.collection.path,
      id,
    ).withConverter(this.collection.converter!);

    return updateDoc(document, { is_active: isActive });
  }

  /**
   * Deletes a document from database
   * @param id Unique id of document to be deleted
   * @returns
   */
  public async deleteDocument(id: string): Promise<void> {
    const document = doc(this.collection.firestore, this.collection.path, id);
    return deleteDoc(document);
  }
}
