import { CollaboratorsCard } from "../Components/colaboratorCard";
import { UsersSelectModal } from "../Components/selectUserModal";
import { Flex, Spinner } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { IProjectPageProps } from "interfaces/IProject";
import { UserService } from "services/UserService";
import CustomButtom from "components/Form/Button";
import { useHistory } from "react-router-dom";
import { IUser } from "interfaces/IUser";

export const Collaborators: React.FC<IProjectPageProps> = ({
  projectId,
  project,
  handleSaveProject,
  setProject,
}) => {
  const history = useHistory();

  const [assignedToUsers, setAssignedToUsers] = useState<IUser[]>([]);
  const [isLoadingProjects, setIsLoadingProjects] = useState(false);
  const [users, setUsers] = useState<IUser[]>([]);

  const userService = new UserService();

  const handleAddCollaborators = useCallback(async (user: IUser) => {
    setUsers((prop) => prop.filter((x) => x.id != user.id));
    setAssignedToUsers((prop) => [...prop, user]);
  }, []);

  const handleRemoveCollaborators = useCallback(async (user: IUser) => {
    setUsers((prop) => [...prop, user]);
    setAssignedToUsers((prop) => prop.filter((x) => x.id != user.id));
  }, []);

  const handleSaveCollaborators = useCallback(
    async (users: IUser[]) => {
      const assigned: string[] = [];

      users.forEach((x) => assigned.push(x.email.split("@")[0]));
      setProject({ ...project, assignedToUsers: assigned });

      await handleSaveProject({ ...project, assignedToUsers: assigned });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assignedToUsers, project],
  );

  const handleGetData = useCallback(async () => {
    setIsLoadingProjects(true);
    const allUsers: IUser[] = [];
    const usersAssigned: IUser[] = [];
    const responseUsers = await userService.getAll();

    // TODO: Passar para service responsabilidade de achar usuarios assigned
    if (responseUsers?.length > 0) {
      responseUsers.forEach((u) => {
        project?.assignedToUsers?.find((x) => x == u.email.split("@")[0])
          ? usersAssigned.push(u)
          : allUsers.push(u);
      });

      setAssignedToUsers(usersAssigned);
      setUsers(allUsers);
    }
    setIsLoadingProjects(false);
  }, [projectId, project]);

  useEffect(() => {
    handleGetData();
  }, [projectId, project]);

  return (
    <>
      <Flex
        flexDir={["column", "column", "row"]}
        maxH={[
          "calc(100vh - 240px)",
          "calc(100vh - 240px)",
          "calc(100vh - 380px)",
        ]}
        mt="5"
        gap="12"
        pr="2"
        pt="2"
      >
        <UsersSelectModal
          loadingUser={isLoadingProjects}
          users={users}
          handleSelectedUser={handleAddCollaborators}
        />

        <Flex justify={"space-between"} flexDir={"column"} flex="1" gap="4">
          <Flex
            flex="1"
            // h="100%"
            minH={["94px"]}
            maxH={["calc(100vh - 700px)", "calc(100vh - 450px)"]}
            gap="3"
            flexDir={"column"}
            overflow={isLoadingProjects ? "hidden" : "auto"}
            pr="2"
          >
            {isLoadingProjects ? (
              <Flex align={"center"} justify={"center"} mt="5">
                <Spinner />
              </Flex>
            ) : (
              <>
                {assignedToUsers
                  .sort((a, b) =>
                    `${a?.firstName} ${a.lastName}`?.localeCompare(
                      `${b?.firstName} ${b?.lastName}`,
                    ),
                  )
                  .map((item, index) => {
                    return (
                      <CollaboratorsCard
                        key={index}
                        user={item}
                        handleRemoveUser={handleRemoveCollaborators}
                      />
                    );
                  })}
              </>
            )}
          </Flex>

          <Flex align={"center"} justify={"flex-end"} mb="4">
            <CustomButtom
              primaryText="Cancelar"
              py="6"
              px="8"
              onClick={() => {
                history.push("/projects");
              }}
            />
            <CustomButtom
              primaryText="Salvar dados do projeto"
              bg="orange2"
              color="white"
              isLoading={isLoadingProjects}
              _hover={{
                opacity: 0.8,
              }}
              onClick={async () => {
                await handleSaveCollaborators(assignedToUsers);
              }}
              py="6"
              px="10"
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
