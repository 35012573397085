import { Document } from "entities/Document";

import { FirestoreService, ECollections as coll } from "../firebase";

export class DocumentsRepository {
  private firestore: FirestoreService;

  constructor() {
    this.firestore = new FirestoreService();
  }

  public async getAllDocsById(id: string): Promise<Document[]> {
    const documents = await this.firestore.findDocumentByQuery(
      coll.DOCUMENTS,
      "owner.id",
      "==",
      id,
    );

    return documents.map((document) => {
      return { ...document.data, id: document.id };
    });
  }

  public async getAllDocuments(): Promise<Document[]> {
    const documents = await this.firestore.getAllDocuments(coll.DOCUMENTS);
    return documents.map<Document>((doc) => {
      return Document.fromJson(doc.data, doc.id);
    });
  }

  public async updateDoc(document: Document): Promise<Document> {
    const documents = await this.firestore.updateDocument(
      coll.DOCUMENTS,
      document,
      document.id ?? "",
    );
    return documents;
  }

  public async createDoc(document: Document): Promise<Document> {
    const documents = await this.firestore.setDocument(
      coll.DOCUMENTS,
      document.toJson(),
    );
    return documents;
  }

  public async deleteDoc(id: string): Promise<boolean> {
    await this.firestore.deleteDocument(coll.DOCUMENTS, id);
    return true;
  }
}
