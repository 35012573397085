import { useUnit } from "effector-react";

import { store } from "./store";
import * as actions from "./actions";

export function useMenu() {
  const { isActive, isVisible, title } = useUnit(store);

  return {
    isActive,
    isVisible,
    title,
    ...actions,
  };
}
