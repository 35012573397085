import { CheckCircleIcon, DeleteIcon } from "@chakra-ui/icons";
import { Flex, Icon, Text } from "@chakra-ui/react";
import { ICustomer } from "interfaces/ICustomer";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

interface IClientCardProps {
  client: any;
  handleOpenModalConfirm: (customer?: ICustomer) => void;
}

export const ClientCard: React.FC<IClientCardProps> = ({
  client,
  handleOpenModalConfirm,
}) => {
  const history = useHistory();

  const handleIsActive = useCallback(() => {
    handleOpenModalConfirm(client);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      bg="grayBg"
      p="2"
      align={"center"}
      justify={"space-between"}
      _hover={{
        opacity: 0.7,
      }}
      cursor={"pointer"}
      transition={"ease-in-out"}
    >
      <Flex
        w="100%"
        alignContent={"flex-start"}
        gap="6"
        align={"center"}
        // justify={'space-between'}
        flexDir={["column", "column", "row"]}
        onClick={() => history.push(`/clients/create/${client.id}`)}
      >
        <Flex
          bg="white"
          align={"center"}
          justify={"center"}
          p="2"
          border="5px solid"
          borderColor={"orange2"}
          borderRadius={"6px"}
        >
          <Text fontWeight={"bold"}>{client.name[0]}</Text>
        </Flex>

        <Flex flexDir={"column"} align={"flex-start"} justify={"center"}>
          <Text color="grayLight" fontSize={["xs"]}>
            Nome do cliente
          </Text>
          <Text fontSize={["sm"]}>{client.name}</Text>
        </Flex>
      </Flex>

      <Flex
        minW={["200px"]}
        h="100%"
        gap={8}
        align={"center"}
        justify={"space-between"}
        flexDir={["column", "column", "row"]}
      >
        <Flex
          flexDir={"column"}
          align={"flex-start"}
          justify={"center"}
          onClick={() => history.push(`/clients/create/${client.id}`)}
        >
          <Text color="grayLight" fontSize={["xs"]}>
            Status
          </Text>
          <Text fontSize={["sm"]}>
            {client.isActive ? <>Ativado</> : <>Desativado</>}
          </Text>
        </Flex>

        <Flex
          color={client.isActive ? "red" : "orange"}
          align={"center"}
          justify={"center"}
          gap={2}
          cursor={"pointer"}
          fontSize={["sm"]}
          p="2"
          onClick={() => handleIsActive()}
        >
          <Icon as={client.isActive ? DeleteIcon : CheckCircleIcon} />
          <Text>{client.isActive ? <>Desativar</> : <>Reativar</>}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
