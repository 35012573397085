import { CheckIcon, EditIcon } from "@chakra-ui/icons";
import { Flex, Img, Spinner, Text, useToast } from "@chakra-ui/react";
import CustomButtom from "components/Form/Button";
import CustomCheckBox from "components/Form/CustomCheckBox";
import InputWithLabel from "components/Form/input/InputWithLabel";
import MultiSelect from "components/Form/MultSelect";
// import Dropdown from "components/Form/Select";
import ModalWrapper from "components/Modal";
// import { Journeys } from "entities/Journeys";
import { IJourney } from "interfaces/IJourney";
import { IUserSelected } from "interfaces/IUser";
import HeaderInside from "layouts/HeaderInside";
import { useCallback, useEffect, useState } from "react";
import { JourneysService } from "services/JourneysService";
import { UserService } from "services/UserService";

interface IModalAddJourney {
  isOpen: boolean;
  onClose: () => void;
  handleSalveJourney?: (journey: { id: string; name: string }) => void;
  selectedJourney?: { id: string; name: string };
  setManagersOfJourney: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        name: string;
        email: string;
      }[]
    >
  >;
}

export const ModalAddJourney: React.FC<IModalAddJourney> = ({
  isOpen,
  onClose,
  handleSalveJourney,
  selectedJourney,
  setManagersOfJourney,
}) => {
  const journeysService = new JourneysService();
  const userService = new UserService();

  const toast = useToast();

  const [inputSearch, setInputSearch] = useState("");
  const [loadingJourneys, seLoadingJourneys] = useState(false);
  const [openModalCreateJourney, setOpenModalCreateJourney] = useState(false);
  const [journeys, setJourneys] = useState<IJourney[]>([]);
  const [journeysFiltered, setJourneysFiltered] = useState<IJourney[]>([]);
  const [selectedJourneys, setSelectedJourneys] = useState<IJourney[]>([]);
  const [users, setUsers] = useState<IUserSelected[]>([]);
  const [newJourney, setNewJourney] = useState({} as IJourney);
  const [selectedTAMs, setSelectedTAMs] = useState([""]);

  const handleGetUsersToManagersOption = useCallback(async () => {
    const responseUsers = await userService.getAllUsersByRoles([
      "admin",
      "manager",
    ]);
    setUsers(responseUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetJourneys = useCallback(async () => {
    seLoadingJourneys(true);
    const response = await journeysService.getAllJourneys();

    selectedJourney?.id &&
      setSelectedJourneys(() => {
        const foundJourney = response?.find(
          (item) => item.id === selectedJourney.id,
        );

        if (foundJourney) return [foundJourney];
        return [] as IJourney[];
      });

    setJourneysFiltered(response);
    setJourneys(response);
    seLoadingJourneys(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJourney]);

  const handleCreateOrUpdateJourney = useCallback(
    async (newJourney: IJourney) => {
      seLoadingJourneys(true);

      const foundedManagers = users.reduce((acc, item) => {
        if (selectedTAMs.includes(`${item.firstName} ${item.lastName}`)) {
          acc[item.id] = {
            name: `${item.firstName} ${item.lastName}`,
            email: item.email,
          };
        }
        return acc;
      }, {});

      if (!newJourney.name || !foundedManagers) {
        seLoadingJourneys(false);

        return toast({
          title: "Ocorreu um erro.",
          description: "Erro ao criar uma jornada",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }

      let response;
      if (newJourney.id) {
        response = await journeysService.patchJourneys(
          { ...newJourney, managers: foundedManagers },
          newJourney.id,
        );
      } else {
        response = await journeysService.postJourneys({
          ...newJourney,
          managers: foundedManagers,
        });
      }

      if (!response) {
        setOpenModalCreateJourney(false);
        seLoadingJourneys(false);

        return toast({
          title: "Ocorreu um erro.",
          description: "Erro ao criar uma jornada",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }

      const managers = Object.entries(foundedManagers || {}).map(
        ([id, manager]: any) => ({
          id,
          name: manager.name,
          email: manager.email,
        }),
      );

      setManagersOfJourney(managers);

      toast({
        title: "Dados inseridos com sucesso.",
        description: "",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      setInputSearch("");

      seLoadingJourneys(true);
      const responseJourneys = await journeysService.getAllJourneys();

      setJourneysFiltered(responseJourneys);
      setJourneys(responseJourneys);
      seLoadingJourneys(false);

      setOpenModalCreateJourney(false);
      seLoadingJourneys(false);
      setSelectedJourneys([
        {
          ...newJourney,
          id: newJourney.id,
          managers: foundedManagers,
        },
      ]);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [users, selectedTAMs],
  );

  useEffect(() => {
    handleGetJourneys();
    handleGetUsersToManagersOption();
  }, [selectedJourney]);

  return (
    <>
      <ModalWrapper
        isOpen={isOpen}
        onClose={() => {
          // eslint-disable-next-line no-empty-function
        }}
        isShowCloseButton
        w="400px"
      >
        <Flex
          bg="grayBg"
          borderRadius={"8px 8px 0 0"}
          w="100%"
          align={"center"}
          justify={"center"}
          h="80px"
        >
          <Flex borderBottom="1px solid orange" gap="3" w="90%">
            <InputWithLabel
              isShowBorder={false}
              placeholder="Pesquisar jornada"
              value={inputSearch}
              onChange={(e) => {
                const searchValue = e.target.value.toLocaleLowerCase();

                if (searchValue) {
                  const filteredJourneys = journeys.filter((item) =>
                    item.name.toLocaleLowerCase().includes(searchValue),
                  );
                  setJourneysFiltered(filteredJourneys);
                } else {
                  setJourneysFiltered(journeys);
                }
                setInputSearch(e.target.value.toLocaleLowerCase());
              }}
            />

            <CustomButtom
              primaryText="Adicionar"
              bg="orange2"
              color="white"
              h="30px"
              fontSize="xs"
              onClick={() => {
                setNewJourney(
                  journeysFiltered.length === 0
                    ? ({
                        name: inputSearch,
                        description: `Jornada ${inputSearch}`,
                      } as IJourney)
                    : ({} as IJourney),
                );
                setOpenModalCreateJourney(!openModalCreateJourney);
              }}
            />
          </Flex>
        </Flex>

        <Flex
          flexDir={"column"}
          py="3"
          px="5"
          overflow={"auto"}
          maxH="200px"
          minH="200px"
          gap="3"
        >
          {loadingJourneys ? (
            <Flex align={"center"} justify={"center"} h={"150px"} w="100%">
              <Spinner />
            </Flex>
          ) : (
            <>
              {journeysFiltered?.map((journey) => {
                return (
                  <Flex
                    align={"center"}
                    justify={"space-between"}
                    w="100%"
                    key={journey.id}
                  >
                    <Flex gap="3" align={"center"}>
                      <CustomCheckBox
                        isChecked={selectedJourneys.some(
                          (item) => item.id === journey.id,
                        )}
                        onChange={() => {
                          if (
                            selectedJourneys.some(
                              (item) => item.id === journey.id,
                            )
                          ) {
                            setSelectedJourneys([]);
                          } else {
                            setSelectedJourneys([journey]);
                          }
                        }}
                      />

                      <Flex flexDir={"column"}>
                        <Text fontWeight={"400"} fontSize={"sm"}>
                          {journey?.name}
                        </Text>
                        <Text fontWeight={"400"} fontSize={"xs"}>
                          {journey?.description}
                        </Text>
                      </Flex>
                    </Flex>

                    <Flex align={"center"} gap="3">
                      <Img
                        as={EditIcon}
                        w="18px"
                        h="18px"
                        cursor={"pointer"}
                        onClick={() => {
                          setNewJourney(journey);
                          setOpenModalCreateJourney(true);
                        }}
                      />
                      {!!selectedJourneys.find(
                        (item) => item.id === journey.id,
                      ) && (
                        <Img as={CheckIcon} color="green" w="18px" h="18px" />
                      )}
                    </Flex>
                  </Flex>
                );
              })}
            </>
          )}
        </Flex>

        <Flex align={"center"} justify={"flex-end"} w="100%" p="3">
          <CustomButtom
            primaryText="Cancelar"
            w="120px"
            h="48px"
            onClick={() => {
              onClose();
            }}
          />
          <CustomButtom
            h="48px"
            primaryText="Salvar"
            bg="orange2"
            color="white"
            w="120px"
            onClick={() =>
              handleSalveJourney && handleSalveJourney(selectedJourneys[0])
            }
          />
        </Flex>
      </ModalWrapper>

      <ModalWrapper
        isOpen={openModalCreateJourney}
        onClose={() => setOpenModalCreateJourney(!openModalCreateJourney)}
        w="370px"
        borderRadius="9px"
      >
        <HeaderInside borderRadius="8px 8px 0 0 ">
          <Text as="span" fontWeight={"bold"} color="grayBg" fontSize={["md"]}>
            Nova jornada
          </Text>
        </HeaderInside>

        <Flex flexDir={"column"} py="4" px="5" gap="3">
          <InputWithLabel
            label="Nome da Jornada"
            name={"name"}
            value={newJourney.name}
            onChange={(e) =>
              setNewJourney({ ...newJourney, [e.target.name]: e.target.value })
            }
          />
          <InputWithLabel
            label="Descrição"
            name={"description"}
            value={newJourney.description}
            onChange={(e) =>
              setNewJourney({ ...newJourney, [e.target.name]: e.target.value })
            }
          />

          <MultiSelect
            options={users.map((item) => {
              return {
                id: item.id,
                label: `${item.firstName} ${item.lastName}`,
              };
            })}
            handleChangeItems={setSelectedTAMs}
            selectedOptions={Object?.values(newJourney?.managers || {}).map(
              (item) => item.name,
            )}
          />
        </Flex>

        <Flex align={"center"} justify={"flex-end"} w="100%" p="3">
          <CustomButtom
            primaryText="Cancelar"
            w="120px"
            h="48px"
            onClick={() => {
              setOpenModalCreateJourney(false);
            }}
          />
          <CustomButtom
            h="48px"
            primaryText="Salvar"
            bg="orange2"
            color="white"
            w="120px"
            onClick={() => handleCreateOrUpdateJourney(newJourney)}
          />
        </Flex>
      </ModalWrapper>
    </>
  );
};
