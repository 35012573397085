import { DocumentsRepository } from "repositories/DocumentsRepository";
import { IDocumentData } from "interfaces/IDocuments";
import { Document } from "entities/Document";

export class DocumentsService {
  private documentsRepository = new DocumentsRepository();

  convertToInterface(document: Document): IDocumentData {
    return <IDocumentData>{
      id: document.id,
      uri: document.uri,
      type: document.type,
      owner: document.owner,
      created_at: document.createdAt,
      updated_at: document.updatedAt,
      name: document.name,
      description: document.description,
      expires_at: document.expires_at,
    };
  }

  convertToEntity(document: IDocumentData): Document {
    return new Document(
      document.id,
      document.uri,
      document.type,
      document.owner,
      document.created_at,
      document.updated_at,
      document.name,
      document.description,
      document.expires_at,
    );
  }

  public async getAllDocuments(): Promise<IDocumentData[]> {
    const documents = await this.documentsRepository.getAllDocuments();

    return documents.map((document) => this.convertToInterface(document));
  }

  public async getDocsById(id: string): Promise<IDocumentData[]> {
    const documents = await this.documentsRepository.getAllDocsById(id);

    return documents.map((document) => this.convertToInterface(document));
  }

  public async updateDocument(document: IDocumentData) {
    const doc = this.convertToEntity(document);
    return await this.documentsRepository.updateDoc(doc);
  }

  public async createDocument(document: IDocumentData) {
    const doc = this.convertToEntity(document);
    return await this.documentsRepository.createDoc(doc);
  }

  public async deleteDocument(id: string) {
    return await this.documentsRepository.deleteDoc(id || "");
  }
}
