import React, { useCallback, useEffect, useRef, useState } from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Box, Flex, Img } from "@chakra-ui/react";
import "./DatePicker.css";
import InputWithLabel from "components/Form/input/InputWithLabel";
import Icon from "react-multi-date-picker/components/icon";
import leftArrow from "assets/img/leftArrow.svg";
import rightArrow from "assets/img/rightArrow.svg";
import locale from "./utils";

interface DateInputProps {
  onChange: (date?: Date) => void;
  value?: Date;
  label?: string;
  maxW?: string;
  minW?: string;
  readOnly?: boolean;
  [key: string]: any;
}

const DateInput: React.FC<DateInputProps> = ({
  onChange,
  value,
  label,
  maxW,
  minW,
  readOnly,
  ...rest
}) => {
  const [selected, setSelected] = useState(value);
  const [inputValue, setInputValue] = useState({
    value: "",
  });

  const datePickerRef = useRef<any>();

  const formatDate = (date?: Date) => {
    return date
      ? date.toISOString().split("T")[0].split("-").reverse().join("/")
      : "";
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setInputValue({ ...inputValue, value: input });
  };

  const handleChange = useCallback(
    (date: Date | null, event?: React.SyntheticEvent) => {
      if (date && event?.type === "click") {
        setInputValue({ value: formatDate(date) });
        setSelected(date);
        onChange(date); // Somente atualiza ao clicar no calendário
      }
    },
    [onChange, readOnly],
  );
  const handleBlur = () => {
    let input = inputValue.value.replace(/\D/g, "");

    if (input.length >= 2) {
      input =
        `${input.slice(0, 2)}/${input.slice(2, 4)}/${input.slice(4, 8)}`.replace(
          /\/+$/,
          "",
        );
    }

    if (input.length !== 10) {
      setInputValue({ value: input });
      return;
    }

    const [day, month, year] = input.split("/").map(Number);

    if (
      day &&
      month &&
      year &&
      !isNaN(new Date(year, month - 1, day).getTime())
    ) {
      const newDate = new Date(year, month - 1, day);

      setInputValue({ value: formatDate(newDate) });
      setSelected(newDate);
      onChange(newDate);
    } else {
      setInputValue({ value: "" });
      setSelected(undefined);
      onChange(undefined);
    }
  };

  const MyContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <Box style={{ position: "relative" }}>{children}</Box>
      </CalendarContainer>
    );
  };

  useEffect(() => {
    setInputValue({ value: formatDate(value) }); // Formatar o valor inicial corretamente
    setSelected(value);
  }, [value]);

  return (
    <Flex>
      <DatePicker
        popperPlacement="bottom"
        selected={selected}
        onChange={handleChange}
        ref={datePickerRef}
        locale={locale}
        calendarContainer={MyContainer}
        disabled={readOnly}
        renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth }) => {
          return (
            <Flex m="10px" justify={"space-between"} align={"center"}>
              <span>
                {monthDate.toLocaleString("default", { month: "long" })} de{" "}
                {monthDate.getFullYear()}
              </span>
              <Flex>
                <button
                  onClick={decreaseMonth}
                  style={{ marginRight: 3, padding: "5px" }}
                >
                  <Img src={leftArrow} />
                </button>

                <button
                  onClick={increaseMonth}
                  style={{ marginLeft: 3, padding: "5px" }}
                >
                  <Img src={rightArrow} />
                </button>
              </Flex>
            </Flex>
          );
        }}
        customInput={
          <Flex align={"center"} justify={"center"} gap="5px" color="black">
            <InputWithLabel
              value={inputValue.value}
              maxLength={10}
              onChange={handleInputChange}
              onBlur={handleBlur}
              onMouseOut={() => {
                handleBlur();
              }}
              maxW={maxW}
              minW={minW}
              label={label}
              icon={
                <Icon
                  onClick={() => {
                    if (!readOnly) {
                      datePickerRef.current.setOpen(
                        !datePickerRef.current.isCalendarOpen(),
                      );
                    }
                  }}
                />
              }
              {...rest}
              style={{
                cursor: readOnly ? "not-allowed" : "pointer",
              }}
              readOnly={readOnly}
            />
          </Flex>
        }
      />
    </Flex>
  );
};

export default React.memo(DateInput);
