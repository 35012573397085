import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from "@chakra-ui/react";

type ModalWrapperProps = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  isShowCloseButton?: boolean;
  whiteIcon?: boolean;
  [key: string]: any;
};

const ModalWrapper: React.FC<ModalWrapperProps> = ({
  isOpen,
  onClose,
  children,
  isShowCloseButton,
  whiteIcon,
  ...rest
}) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent bg="white" color="black" ref={undefined} {...rest}>
          {!isShowCloseButton && (
            <ModalCloseButton color={whiteIcon ? "white" : "black"} />
          )}
          {children}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalWrapper;
