import { Flex, Button, Text } from "@chakra-ui/react";

const Pagination = ({ currentPage, totalPages, onPageChange, loading }) => {
  const renderPageNumbers = () => {
    const pageNumbers: any[] = [];
    const maxPageButtons = 1;

    const startPage = Math.max(1, currentPage - maxPageButtons);
    const endPage = Math.min(totalPages, currentPage + maxPageButtons);

    if (startPage > 1) {
      pageNumbers.push(
        <Button
          key={1}
          mx={1}
          onClick={() => onPageChange(1)}
          isDisabled={loading}
        >
          1
        </Button>,
      );
      if (startPage > 2) {
        pageNumbers.push(
          <Text key="start-ellipsis" mx={1}>
            ...
          </Text>,
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Button
          key={i}
          variant={i === currentPage ? "outline" : "solid"}
          colorScheme={i === currentPage ? "blue" : "blue"}
          onClick={() => onPageChange(i)}
          mx={1}
          isDisabled={loading}
        >
          <Text color="black">{i}</Text>
        </Button>,
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push(
          <Text key="end-ellipsis" mx={1}>
            ...
          </Text>,
        );
      }
      pageNumbers.push(
        <Button
          key={totalPages}
          mx={1}
          onClick={() => onPageChange(totalPages)}
          isDisabled={loading}
        >
          {totalPages}
        </Button>,
      );
    }

    return pageNumbers;
  };

  return (
    <Flex w="100%" align="center" justify="center" mt={3}>
      {renderPageNumbers()}
    </Flex>
  );
};

export default Pagination;
