import { Flex, Text, useToast } from "@chakra-ui/react";
import CustomButtom from "components/Form/Button";
import InputWithLabel from "components/Form/input/InputWithLabel";
import HeaderInside from "layouts/HeaderInside";

import { UploadButton } from "components/Form/UploadButton";
import { CustomerService } from "services/CustomerService";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ICustomer } from "interfaces/ICustomer";

export const ClientCreate: React.FC = () => {
  const [imageBlob, setImageBlob] = useState<Blob | null>(null);

  const customerService = new CustomerService();

  const history = useHistory();
  const { clientId } = useParams<{ clientId: string }>();
  const toast = useToast();
  const [customer, setCustomer] = useState({
    name: "",
  } as ICustomer);
  const [loading, setLoading] = useState(false);

  const handleInsertImg = useCallback(async (id, image) => {
    const response = await customerService.uploadImgCustomer(
      image,
      `application/customers/${id}/images`,
    );
    if (response.error) return false;
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetCustomerById = useCallback(async () => {
    setLoading(true);

    const response = await customerService.getById(clientId);

    if (response) {
      setCustomer(response);
      await customerService.getAllCustomersImg(clientId).then((x) => {
        if (response) response.logoPath = `${x.data}`;
      });
    }

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  const handleChangeCustomer = (e: any) => {
    const { name, value } = e.target;
    if (name == "contact.name")
      setCustomer({
        ...customer,
        contact: { ...customer.contact, name: value },
      });
    else if (name == "contact.function")
      setCustomer({
        ...customer,
        contact: { ...customer.contact, function: value },
      });
    else if (name == "contact.email")
      setCustomer({
        ...customer,
        contact: { ...customer.contact, email: value },
      });
    else if (name == "contact.phoneNumber")
      setCustomer({
        ...customer,
        contact: { ...customer.contact, phoneNumber: value },
      });
    else setCustomer({ ...customer, [name]: value });
  };

  const handleSaveClient = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);

      if (customer.name == undefined || customer.name.trim() == "") {
        toast({
          title: "Erro",
          description:
            "O Campo 'Nome do cliente' não foi preenchido corretamente.",
          status: "warning",
          duration: 4000,
          isClosable: true,
        });
        setLoading(false);
        return;
      }

      let response;
      if (clientId) {
        try {
          await customerService.updateCustomer(clientId, customer);

          const responseImg = await handleInsertImg(customer.id, imageBlob);

          if (!responseImg) {
            toast({
              title: "Ocorreu um erro!",
              description: "Erro ao salvar cliente a imagem do cliete!",
              status: "warning",
              duration: 4000,
              isClosable: true,
            });
            setLoading(false);
          }

          response = { ...customer, id: clientId };
        } catch (err) {
          toast({
            title: "Ocorreu um erro!",
            description: "Erro ao salvar cliente!",
            status: "error",
            duration: 4000,
            isClosable: true,
          });
          setLoading(false);
        }
      } else {
        response = await customerService.postCustomer({
          ...customer,
          isActive: true,
        });
        const responseImg = await handleInsertImg(response.id, imageBlob);
        if (!responseImg) {
          toast({
            title: "Ocorreu um erro!",
            description: "Erro ao salvar cliente a imagem do cliete!",
            status: "warning",
            duration: 4000,
            isClosable: true,
          });
        }
      }

      if (!response) {
        toast({
          title: "Ocorreu um erro!",
          description: "Erro ao salvar cliente!",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
        setLoading(false);
        return;
      }

      toast({
        title: "Dados inseridos com sucesso!",
        description: "Cliente salvo com sucesso!",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      history.push("/clients");

      setLoading(false);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientId, customer, history, imageBlob, toast],
  );

  useEffect(() => {
    if (clientId) handleGetCustomerById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  return (
    <>
      {" "}
      <HeaderInside>
        <Flex justify={"space-between"} align={"center"} flex="1" gap="4">
          <Text fontSize={["md"]}>Criação de cliente</Text>
        </Flex>
      </HeaderInside>
      <Flex
        p="5"
        mt="12"
        align={"center"}
        justify={"space-evenly"}
        gap="6"
        overflow={"auto"}
        flexDir={["column", "column", "row"]}
      >
        <UploadButton
          setImageBlob={setImageBlob}
          imageBlob={imageBlob}
          imgUrl={customer.logoPath}
          loading={loading}
        />

        <form onSubmit={handleSaveClient}>
          <Flex
            flexDir={"column"}
            gap="5"
            maxH="calc(100vh - 400px)"
            overflow={"auto"}
          >
            <InputWithLabel
              label="Nome do cliente"
              name="name"
              value={customer.name}
              onChange={(e) => handleChangeCustomer(e)}
              required
            />
            <InputWithLabel
              label="Segmento da empresa"
              name="segment"
              value={customer.segment}
              onChange={(e) => handleChangeCustomer(e)}
            />
            <Flex gap="5">
              <InputWithLabel
                label="CNPJ"
                name="cnpj"
                value={customer.cnpj}
                onChange={(e) => handleChangeCustomer(e)}
              />
              <InputWithLabel
                label="Telefone"
                name="contact.phoneNumber"
                value={customer.contact?.phoneNumber}
                onChange={(e) => handleChangeCustomer(e)}
              />
            </Flex>
            <InputWithLabel
              label="Responsavel"
              name="contact.name"
              value={customer.contact?.name}
              onChange={(e) => handleChangeCustomer(e)}
            />
            <InputWithLabel
              label="Cargo"
              name="contact.function"
              value={customer.contact?.function}
              onChange={(e) => handleChangeCustomer(e)}
            />
            <InputWithLabel
              label="email"
              name="contact.email"
              value={customer.contact?.email}
              onChange={(e) => handleChangeCustomer(e)}
            />
            <InputWithLabel
              label="website"
              name="website"
              value={customer.website}
              onChange={(e) => handleChangeCustomer(e)}
            />

            <Flex align={"center"} justify={"space-between"}>
              <CustomButtom
                primaryText="Cancelar"
                _hover={{
                  opacity: 0.8,
                }}
                fontSize={"sm"}
                color="orange2"
                px="8"
                py="6"
                onClick={() => history.push("/clients")}
              />
              <CustomButtom
                primaryText="Salvar"
                _hover={{
                  opacity: 0.8,
                }}
                bg="orange2"
                color="white"
                type="submit"
                fontSize={"sm"}
                px="10"
                py="6"
                isLoading={loading}
              />
            </Flex>
          </Flex>
        </form>
      </Flex>
    </>
  );
};
