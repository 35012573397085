import { Flex, Img, Spinner, Text } from "@chakra-ui/react";
import Upload from "assets/img/upload.svg";
import { useState } from "react";

interface IUploadButton {
  setImageBlob: React.Dispatch<React.SetStateAction<Blob | null>>;
  imgUrl?: string;
  loading?: boolean;
  imageBlob: Blob | null;
  label?: string;
}

export const UploadButton: React.FC<IUploadButton> = ({
  setImageBlob,
  imgUrl,
  loading,
  imageBlob,
  label,
}) => {
  const [previewUrl, setPreviewUrl] = useState<string | undefined>();

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setImageBlob(file);
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setPreviewUrl(fileReader.result as string);
      };
      fileReader.readAsDataURL(file);
    }
  };

  return (
    <label>
      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleFileInputChange}
      />
      <Flex
        as="div"
        border={"1px solid"}
        borderStyle={"dashed"}
        height={"180px"}
        width={"200px"}
        flexDir={"column"}
        align={"center"}
        justify={"center"}
        borderRadius={"8px"}
        cursor={"pointer"}
        position="relative"
      >
        {loading ? (
          <Spinner />
        ) : (
          <Img
            height={"auto"}
            width={"100%"}
            maxHeight={imgUrl || previewUrl ? "100%" : "20%"} // Garante que a imagem não ultrapasse o quadrado
            src={
              previewUrl ||
              imgUrl ||
              (imageBlob !== null ? URL.createObjectURL(imageBlob) : Upload) ||
              Upload
            }
            alt="Logo"
            position="absolute"
            top={imgUrl || previewUrl ? 0 : 70}
            left={0}
            bottom={0}
            right={0}
            objectFit={"contain"}
          />
        )}
        <Text mt="60px" fontSize={"sm"}>
          {(!previewUrl && !imgUrl && !imageBlob && label) || "Logotipo"}
        </Text>
      </Flex>
    </label>
  );
};
