import { IProject } from "../interfaces/IProject";
import { IProjectTypes } from "interfaces/IProjectTypes";
import { FirestoreRepository } from "repositories/FirestoreRepository";
import { Project, ProjectSteps, ProjectRisk } from "entities/Project";
import { ProjectType } from "entities/ProjectType";

export class ProjectService {
  constructor(
    private projectRepository = new FirestoreRepository<Project>(
      "projects",
      Project.fromJson,
    ),
    private projectTypeRepository = new FirestoreRepository<ProjectType>(
      "project_types",
      ProjectType.fromJson,
    ),
  ) {}

  public steps = new Set([
    "Backlog",
    "Discovery & Assessment",
    "Planejamento",
    "Levantamento Técnico",
    "Desenvolvimento",
    "Teste Integrado",
    "Homologação",
    "Go Live",
    "Finalizado",
    "Suporte Técnico",
    "Alocação",
  ]);

  public risks = new Set(["baixo", "médio", "neutro", "alto", "muito alto"]);

  convertToEntity(project: IProject): Project | undefined {
    if (
      this.steps.has(project.currentStep) &&
      this.risks.has(project.risk.toLocaleLowerCase())
    ) {
      return new Project(
        project.name,
        project.code,
        project.managers,
        project.currentStep as ProjectSteps,
        project.billable,
        project.isBlocked,
        project.risk as ProjectRisk,
        project.type,
        project.isActive,
        project.customer,
        project.createdAt,
        project.updatedAt,
        project.journey,
        project.description,
        project.successCriteria,
        project.learningSummary,
        project.startedAt,
        project.endedAt,
        project.expectedStart,
        project.expectedEnd,
        project.additionalHours,
        project.soldHours,
        project.outcome,
        project.revenueRecognition,
        project.assignedToUsers,
      );
    }
  }

  convertToInterface(project: Project): IProject {
    return {
      id: project.id,
      name: project.name,
      description: project.description,
      code: project.code,
      managers: project.managers,
      currentStep: project.currentStep,
      isBlocked: project.isBlocked,
      learningSummary: project.learningSummary,
      successCriteria: project.successCriteria,
      createdAt: project.createdAt,
      updatedAt: project.updatedAt,
      startedAt: project.startedAt,
      journey: project.journey,
      endedAt: project.endedAt,
      expectedStart: project.expectedStart,
      expectedEnd: project.expectedEnd,
      billable: project.billable,
      outcome: project.outcome,
      assignedToUsers: project.assignedToUsers,
      risk: project.risk,
      revenueRecognition: project.revenueRecognition,
      soldHours: project.soldHours,
      isActive: project.isActive,
      type: project.type,
      customer: project.customer,
    };
  }

  public async getProjectsByEmail(userEmail: string): Promise<IProject[]> {
    const result = await this.projectRepository.queryDocuments([
      {
        field: "assigned_to_users",
        operator: "array-contains",
        value: userEmail.split("@")[0],
      },
    ]);

    return result.docs.map((projects) => {
      return this.convertToInterface(projects.data());
    });
  }

  public async getAllProjects(): Promise<IProject[]> {
    const result = await this.projectRepository.getDocuments();

    return result.docs.map((project) => {
      return this.convertToInterface(project.data());
    });
  }

  public async getProjectsById(
    projectId: string,
  ): Promise<IProject | undefined> {
    const result = (await this.projectRepository.getDocument(projectId)).data();
    if (result) return this.convertToInterface(result);
  }

  public async getProjectType(): Promise<IProjectTypes[]> {
    const result = await this.projectTypeRepository.getDocuments();
    return result.docs.map((type) => {
      const typeData = type.data();
      return <IProjectTypes>{
        id: type.id,
        name: typeData.name,
        created_at: typeData.createdAt,
        description: typeData.description,
        updated_at: typeData.updatedAt,
      };
    });
  }

  public async createProject(project: IProject): Promise<string | undefined> {
    const entity = this.convertToEntity(project);
    if (entity) {
      const result = await this.projectRepository.createDocument(entity);
      if (result) return result.id;
    }
  }

  public async updateProject(project: IProject, id: string): Promise<any> {
    const entity = this.convertToEntity(project);

    if (entity) return await this.projectRepository.updateDocument(entity, id);
  }

  public async toggleIsActive(project: IProject, id: string): Promise<any> {
    if (project?.isActive)
      return await this.projectRepository.updateIsActive(!project.isActive, id);
  }
}
