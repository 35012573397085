import { Flex, Text } from "@chakra-ui/react";

interface VideoPlayerProps {
  videoId: string;
  name: string;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoId, name }) => {
  const videoUrl = `https://drive.google.com/file/d/${videoId}/preview`;

  return (
    <Flex
      py="3"
      px="4"
      align={"center"}
      justify={"center"}
      flexDir={"column"}
      borderRadius={"8px"}
      bg="grayBg"
    >
      <iframe
        src={videoUrl}
        width="350"
        height="200"
        allowFullScreen
        allow="autoplay"
      ></iframe>

      <Text mt="3" fontWeight={"bold"}>
        {name}
      </Text>
    </Flex>
  );
};
