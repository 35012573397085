import React from "react";
import { Select, Box, Text } from "@chakra-ui/react";

type Props = {
  children?: React.ReactNode;
  label?: string;
  required?: boolean; // Adicionando a propriedade required
  [key: string]: any; // Permitir outras propriedades arbitrárias
};

const Dropdown: React.FC<Props> = ({ children, label, required, ...rest }) => {
  return (
    <Box w="100%" pos="relative">
      {label && (
        <Text
          fontSize={"12px"}
          fontWeight={"normal"}
          pos="absolute"
          bottom="35px"
          color={"grayLight"}
        >
          {label}{" "}
          {required && (
            <Text as="span" color="red">
              *
            </Text>
          )}
        </Text>
      )}
      <Select
        variant="flushed"
        focusBorderColor="yellow"
        borderColor="yellow"
        cursor={"pointer"}
        ref={undefined}
        {...rest}
      >
        {children}
      </Select>
    </Box>
  );
};

export default Dropdown;
