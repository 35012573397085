import { registerLocale } from "react-datepicker";
import { enUS } from "date-fns/locale";

// Definição do objeto de localidade personalizada
const gregorianEnLowercase: any = {
  ...enUS,
  code: "gregorian_en_lowercase",
  formatLong: {
    date: () => "dd/MM/yyyy",
    time: () => "HH:mm:ss",
    dateTime: () => "dd/MM/yyyy HH:mm:ss",
  },
  localize: {
    ...enUS.localize,

    day: (n: number, opts: { width?: string }) =>
      opts.width === "short"
        ? ["dom", "seg", "ter", "qua", "qui", "sex", "sab"][n]
        : [
            "Domingo",
            "Segunda-feira",
            "Terça-feira",
            "Quarta-feira",
            "Quinta-feira",
            "Sexta-feira",
            "Sábado",
          ][n],
    ordinalNumber: (n: number) => n,
  },
};

// Registrar a localidade personalizada
registerLocale("gregorian_en_lowercase", gregorianEnLowercase);

export default gregorianEnLowercase;
