import { Search2Icon } from "@chakra-ui/icons";
import { Flex, Img, Spinner, Text } from "@chakra-ui/react";
import InputWithLabel from "components/Form/input/InputWithLabel";
import { IUser } from "interfaces/IUser";
import { useCallback, useEffect, useState } from "react";

interface IUsersSelectModal {
  loadingUser: boolean;
  users: IUser[];
  handleSelectedUser: (user: IUser) => void;
}

export const UsersSelectModal: React.FC<IUsersSelectModal> = ({
  loadingUser,
  users,
  handleSelectedUser,
}) => {
  const [input, setInput] = useState("");
  const [usersFiltered, setUsersFiltered] = useState<IUser[]>([]);

  const handleFilterUsers = useCallback(
    (input?: string) => {
      if (input) {
        const response = users.filter((item) => {
          const fullName = `${item.firstName} ${item.lastName}`;
          return fullName.toLowerCase().includes(input.toLowerCase());
        });
        setUsersFiltered(response.length > 0 ? response : []);
      } else {
        setUsersFiltered(users);
      }
    },
    [users],
  );

  useEffect(() => {
    handleFilterUsers(input);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, users]);

  return (
    <>
      <Flex flex="0.5" flexDir={"column"} pt="2">
        <Flex align={"center"} justify={"center"} w="100%" maxH="30px">
          <InputWithLabel
            label="Pesquisar consultores"
            onChange={(e) => {
              setInput(e.target.value);
            }}
            value={input}
          />
          <Img as={Search2Icon} color="orange2" mt="1" />
        </Flex>

        <Flex
          border={"1px solid"}
          borderColor={"orange2"}
          mt="20px"
          p="3"
          flexDir={"column"}
          gap="2"
          maxH={["150px", "270px"]}
          overflow={"auto"}
          my="2"
        >
          {loadingUser ? (
            <Flex align={"center"} justify={"center"} mt="5">
              <Spinner />
            </Flex>
          ) : (
            usersFiltered
              .sort((a, b) => {
                return a?.email?.localeCompare(b?.email);
              })
              .map((user, index) => (
                <Flex
                  key={index}
                  justify={"space-between"}
                  align={"center"}
                  w="100%"
                  color="GrayText"
                  fontSize={["xs", "sm"]}
                  _hover={{
                    opacity: 0.6,
                  }}
                  cursor={"pointer"}
                >
                  <Text>
                    {user.firstName} {user.lastName}
                  </Text>
                  <Flex
                    border={"2px solid"}
                    borderColor={"orange2"}
                    borderRadius={"100%"}
                    p="1"
                    color="orange2"
                    width="20px"
                    height="20px"
                    justifyContent="center"
                    alignItems="center"
                    fontSize={["md", "xl"]}
                    onClick={() => handleSelectedUser(user)}
                  >
                    +
                  </Flex>
                </Flex>
              ))
          )}
        </Flex>
      </Flex>
    </>
  );
};
