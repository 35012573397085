import TabPanelsContainer from "components/CustomTabs/TabPanelsContainer";
import TabListContainer from "components/CustomTabs/TabListContainer";
import { Flex, Spinner, Tabs, Text, useToast } from "@chakra-ui/react";
import { ProjectService } from "services/ProjectService";
import { TabData } from "components/CustomTabs/types";
import { DataOfProject } from "./dataOfProject";
import { Collaborators } from "./collaborators";
import HeaderInside from "layouts/HeaderInside";
import { IProject } from "interfaces/IProject";
import { HoursControl } from "./hoursControl";
import { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Tasks } from "./tasks";
import { useAuth } from "modules/auth/hooks";

export const CreateProject: React.FC = () => {
  const toast = useToast();
  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };
  const user = useAuth();

  const projectService = new ProjectService();
  const [loading, setLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const { id } = useParams<{ id: string }>();
  const [project, setProject] = useState<IProject>({
    id: id,
    code: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    isActive: true,
    assignedToUsers: undefined,
    customer: { id: "", name: "", email: "", segment: "" },
    name: "",
    description: "",
    outcome: "",
    managers: {},
    currentStep: "",
    isBlocked: false,
    billable: false,
    risk: "",
    type: { id: "", name: "" },
    soldHours: undefined,
    startedAt: undefined,
    endedAt: undefined,
    expectedStart: undefined,
    expectedEnd: undefined,
    learningSummary: undefined,
    successCriteria: undefined,
  });

  const handleSaveProject = useCallback(
    async (newProject: IProject) => {
      const checkFieldsResult = handleFieldsIsCorrect(newProject);
      setProject(newProject);
      if (checkFieldsResult) {
        let response;

        if (id === "0")
          response = await projectService.createProject({
            ...newProject,
            assignedToUsers: [user.data.email.split("@")[0]],
          });
        else {
          try {
            await projectService.updateProject(newProject, id);
            response = { ...newProject, id: id };
          } catch (err) {
            return toast({
              title: "Ocorreu um erro.",
              description: `Erro ao ${id === "0" ? "criar" : "atualizar"} projeto`,
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
        }

        if (response?.error || !response) {
          toast({
            title: "Ocorreu um erro.",
            description: `Erro ao ${id === "0" ? "criar" : "atualizar"} projeto`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Dados atualizados com sucesso.",
            description: "Projeto foi atualizado com sucesso!",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
        return response;
      }
      return undefined;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [project],
  );

  const handleGetProjectData = useCallback(async () => {
    setLoading(true);
    if (id != "0") {
      await projectService.getProjectsById(id).then((result) => {
        if (result) setProject(result);
      });
    }
    setLoading(false);
  }, [id]);

  const handleFieldsIsCorrect = useCallback((object: any) => {
    const camposVazios: string[] = [];
    const translateKeys = [
      { id: "customer", name: "Cliente" },
      { id: "name", name: "Nome do projeto" },
      { id: "currentStep", name: "Fase do projeto" },
      { id: "managers", name: "Responsável hvar" },
      { id: "risk", name: "Risco" },
      { id: "type", name: "Tipo do projeto" },
    ];

    for (const chave in object) {
      if (Object.prototype.hasOwnProperty.call(object, chave)) {
        if (chave === "managers") {
          if (
            object[chave].length == 0 ||
            Object.keys(object[chave]).length === 0 ||
            object[chave]?.name?.trim() === ""
          ) {
            const translatedKey = translateKeys?.find(
              (key) => key.id === "managers",
            )?.name;

            if (translatedKey && !camposVazios.includes(translatedKey)) {
              camposVazios.push(translatedKey);
            }
          }
        }

        const campo = object[chave];
        if (
          campo === null ||
          campo === undefined ||
          campo === "" ||
          campo === "Tipo do projeto" ||
          campo === "Fase do projeto" ||
          campo === "Selecione uma opção" ||
          campo?.name === "Selecione uma opção" ||
          campo?.name === ""
        ) {
          const translatedKey = translateKeys?.find(
            (key) => key.id === chave,
          )?.name;
          if (translatedKey) {
            camposVazios.push(translatedKey);
          }
        }
      }
    }

    if (camposVazios.length > 0) {
      let mensagem;
      if (camposVazios.length > 2) {
        mensagem = `${camposVazios.slice(0, 2).join(", ")}...`;
      } else {
        mensagem = camposVazios.join(", ");
      }

      toast({
        title: "Atenção!",
        description: `Alguns campos estão faltando: ${mensagem}`,
        status: "warning",
        duration: 6000,
        isClosable: true,
      });
      return false;
    }

    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleGetProjectData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [tabsData, setTabsData] = useState<TabData[]>([
    {
      label: "Dados do Projeto",
      content: (
        <>
          <DataOfProject
            projectId={id}
            project={project}
            setProject={setProject}
            handleSaveProject={handleSaveProject}
          />
        </>
      ),
    },
  ]);

  useEffect(() => {
    let tabs: TabData[] = [
      {
        label: "Dados do Projeto",
        content: (
          <>
            <DataOfProject
              projectId={id}
              project={project}
              setProject={setProject}
              handleSaveProject={handleSaveProject}
            />
          </>
        ),
      },
    ];

    if (id != "0") {
      tabs = [
        ...tabs,
        {
          label: "Controle de horas e financeiro",
          content: (
            <>
              <HoursControl
                projectId={id}
                project={project}
                setProject={setProject}
                handleSaveProject={handleSaveProject}
              />
            </>
          ),
        },
        {
          label: "Colaboradores",
          content: (
            <Collaborators
              projectId={id}
              project={project}
              setProject={setProject}
              handleSaveProject={handleSaveProject}
            />
          ),
        },
        {
          label: "Task",
          content: (
            <Tasks
              projectId={id}
              project={project}
              setProject={setProject}
              handleSaveProject={handleSaveProject}
            />
          ),
        },
      ];
    }
    setTabsData(tabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, id]);

  return (
    <>
      <HeaderInside>
        <Flex justify={"space-between"} align={"center"} flex="1" gap="4">
          <Text fontSize={["md"]}>
            {id != "0" ? "Atualização do projeto: " : "Criação de projeto"}

            <Text as="span" fontWeight={"bold"} color="grayBg">
              {project?.name}
            </Text>
          </Text>
        </Flex>
      </HeaderInside>

      <Tabs index={tabIndex} onChange={handleTabChange} p="2">
        <TabListContainer
          tabs={tabsData}
          justifyContent={"center"}
          activeTabIndex={tabIndex}
          onTabChange={handleTabChange}
        />
        {loading ? (
          <Flex
            align={"center"}
            justify={"center"}
            h={"100%"}
            minH="calc(100vh - 400px)"
            w="100%"
          >
            <Spinner />
          </Flex>
        ) : (
          <TabPanelsContainer tabs={tabsData} />
        )}
      </Tabs>
    </>
  );
};
