import { ITask } from "../interfaces/ITask";
import { FirestoreRepository } from "repositories/FirestoreRepository";
import Task from "entities/Task";

export class TaskService {
  private taskRepository: FirestoreRepository<Task>;

  constructor(projectId: string) {
    this.taskRepository = new FirestoreRepository<Task>(
      `projects/${projectId}/tasks`,
      Task.fromJson,
    );
  }

  convertToEntity(task: ITask): Task {
    return new Task(
      task.name,
      task.manager,
      task.isActive,
      task.createdAt,
      task.updatedAt,
      task.description,
      task.expectedStart,
      task.expectedEnd,
      task.expectedHours,
      task.assignedToUsers,
    );
  }

  convertToInterface(task: Task): ITask {
    return <ITask>{
      description: task.description,
      expectedStart: task.expectedStart,
      expectedEnd: task.expectedEnd,
      expectedHours: task.expectedHours,
      id: task.id,
      assignedToUsers: task.assignedToUsers,
      isActive: task.isActive,
      manager: task.manager,
      name: task.name,
      createdAt: task.createdAt,
      updatedAt: task.updatedAt,
    };
  }

  public async getProjectTasks(): Promise<ITask[]> {
    let result: ITask[] = [];
    result = (await this.taskRepository.getDocuments()).docs.map((task) =>
      this.convertToInterface(task.data()),
    );
    return result.sort((a, b) => a.name.localeCompare(b.name));
  }

  public async getAssignedProjectTasks(userId: string): Promise<ITask[]> {
    let result: ITask[] = [];
    result = await this.getProjectTasks();
    return result.filter(
      (item) =>
        item.assignedToUsers?.find(
          (assignedToUsersId) => assignedToUsersId.id === userId,
        ) && item.isActive === true,
    );
  }

  public async updateTask(task: ITask): Promise<any> {
    const entity = this.convertToEntity(task);
    if (entity && task.id)
      return await this.taskRepository.updateDocument(entity, task.id);
  }

  // TODO: rewirte
  public async createTask(task: ITask): Promise<string | undefined> {
    const entity = this.convertToEntity(task);
    if (entity) {
      const result = await this.taskRepository.createDocument(entity);
      if (result) return result.id;
    }
  }

  // TODO: rewirte
  public async toggleIsActiveTask(task: ITask, id: string): Promise<any> {
    return await this.taskRepository.updateIsActive(!task.isActive, id);
  }
}
