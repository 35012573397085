export class ProjectType {
  id: string;
  name: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(
    id: string,
    name: string,
    description: string,
    createdAt: Date,
    updatedAt: Date,
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  static fromJson(json: any, id: string): ProjectType {
    return new ProjectType(
      id,
      json["name"],
      json["description"],
      json["created_at"],
      json["updated_at"],
    );
  }

  toJson(): any {
    return {
      name: this.name,
      description: this.description,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
    };
  }
}
