import React from "react";
import { Input, FormControl, FormLabel } from "@chakra-ui/react";

type InputWithLabelProps = {
  label?: string;
  required?: boolean;
  colorLabel?: string;
  ref?: any;
  maxWid?: string;
  icon?: React.ReactElement;
  isShowBorder?: boolean;
  [key: string]: any;
};

const InputWithLabel: React.FC<InputWithLabelProps> = ({
  label,
  required,
  colorLabel,
  ref,
  maxWid,
  icon,
  isShowBorder = true,
  ...rest
}) => {
  return (
    <FormControl
      variant="floating"
      id="first-name"
      minW={maxWid}
      maxW={maxWid}
      display={"flex"}
      zIndex={0}
      borderBottom={isShowBorder ? "1px solid orange" : ""}
      borderRadius="0px"
      _hover={{
        boxShadow: "none",
        borderBottom: isShowBorder ? "1px solid orange" : "",
        borderRadius: "0px",
      }}
      _focus={{
        boxShadow: "none",
        borderBottom: isShowBorder ? "1px solid orange" : "",
        borderRadius: "0px",
      }}
    >
      <Input
        border="none"
        placeholder=" "
        _selected={{
          borderBottomColor: "orange2",
          color: "blue.500",
          borderBottom: "3px solid ",
        }}
        _focus={{ boxShadow: "none" }}
        p="0"
        ref={ref}
        {...rest}
        fontSize={"sm"}
        autoComplete="off"
        _placeholder={{ opacity: 0.6 }}
      />
      <FormLabel
        fontSize={"sm"}
        whiteSpace={"nowrap"}
        color={colorLabel ? colorLabel : "grayLight"}
      >
        {label}
        {required && <span style={{ color: "red" }}> *</span>}
      </FormLabel>
      {icon}
    </FormControl>
  );
};

export default InputWithLabel;
