import { useEffect, useMemo } from "react";

import Menu from "layouts/Menu";
import { Box, Flex, useBreakpointValue } from "@chakra-ui/react";
import Header from "layouts/Header";
import { useHistory } from "react-router-dom";
import { useAuth } from "modules/auth/hooks";
import BreadCrumb from "components/BreadCrumb";

type Props = {
  children?: React.ReactNode;
};

const Logged: React.FC<Props> = ({ children }) => {
  const history = useHistory();
  const { data } = useAuth();
  const nameSize = useBreakpointValue({
    base: false,
    sm: false,
    md: true,
    lg: true,
  });

  useEffect(() => {
    if (!data?.email) {
      history.push("/");
      window.location.reload();
    }
  }, [data?.email, history]);

  const url = window.location.pathname;

  return (
    <>
      <Flex bg="grayBg" h="100vh" w="100vw" color="black" flexDir="column">
        {useMemo(
          () => (
            <>
              <Header />
              <Menu />
            </>
          ),
          [],
        )}

        <Flex
          align={"center"}
          justify={"center"}
          height={"100%"}
          width={"100%"}
          flexDir="column"
        >
          <BreadCrumb currentPath={url} />

          <Box
            flex="1"
            bg="white"
            borderRadius={2}
            height={"100%"}
            width={"100%"}
            maxW={nameSize ? "calc(100vw - 200px)" : "100vw"}
            maxH={nameSize ? "calc(100vh - 200px)" : "100vh"}
            overflow={"hidden"}
          >
            {children}
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default Logged;
