import { Journeys } from "entities/Journeys";
import { IJourney } from "interfaces/IJourney";
import { FirestoreRepository } from "repositories/FirestoreRepository";

export class JourneysService {
  constructor(
    private journeysRepository = new FirestoreRepository<Journeys>(
      "journeys",
      Journeys.fromJson,
    ),
  ) {}

  convertToInterface(document: Journeys): Journeys {
    return <Journeys>{
      id: document.id,
      name: document.name,
      createdAt: document.createdAt,
      updatedAt: document.updatedAt,
      managers: document.managers,
      description: document.description,
    };
  }

  convertToEntity(journey: IJourney): Journeys {
    const dateNow = new Date();

    return new Journeys(
      journey.id,
      journey.name,
      journey.createdAt ? journey.createdAt : dateNow,
      journey.updatedAt ? journey.updatedAt : dateNow,
      journey.managers,
      journey.description,
    );
  }

  public async getJourneyById(id: string): Promise<IJourney | undefined> {
    const result = (await this.journeysRepository.getDocument(id)).data();

    if (result) return this.convertToInterface(result);
  }

  public async getAllJourneys(): Promise<Journeys[]> {
    const journeys = await this.journeysRepository.getDocuments();
    return journeys.docs.map((document) =>
      this.convertToInterface(document.data()),
    );
  }

  public async postJourneys(document: IJourney): Promise<IJourney> {
    await this.journeysRepository.createDocument(
      this.convertToEntity(document),
    );

    return document;
  }

  public async patchJourneys(
    document: IJourney,
    id: string,
  ): Promise<IJourney> {
    await this.journeysRepository.updateDocument(
      this.convertToEntity(document),
      id,
    );

    return document;
  }
}
