import { useUnit } from "effector-react";

import { store } from "./store";
import * as actions from "./actions";

export function useAuth() {
  const { data, hasError, isLoading } = useUnit(store);

  return {
    data,
    hasError,
    isLoading,
    ...actions,
  };
}
