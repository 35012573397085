import { ChevronRightIcon } from "@chakra-ui/icons";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { useEffect, useState } from "react";

type BreadcrumbItem = {
  label: string;
  href: string;
  color?: string;
};

type BreadcrumbProps = {
  currentPath: string;
};

const BreadCrumb: React.FC<BreadcrumbProps> = ({ currentPath }) => {
  const [items, setItems] = useState<BreadcrumbItem[]>([]);

  const breadcrumbMap: { [key: string]: { label: string; basePath: string } } =
    {
      "/projects": { label: "Listagem de projetos", basePath: "/projects" },
      "/clients": { label: "Listagem de clientes", basePath: "/clients" },
      "/users": { label: "Listagem de usuários", basePath: "/users" },
    };

  const createBreadcrumbItem = (
    label: string,
    href: string,
    color = "",
  ): BreadcrumbItem => ({ label, href, color });

  const getBreadcrumbs = (path: string): BreadcrumbItem[] => {
    const breadcrumbs: BreadcrumbItem[] = [];

    Object.keys(breadcrumbMap).forEach((key) => {
      if (path === key) {
        breadcrumbs.push(createBreadcrumbItem(breadcrumbMap[key].label, key));
      } else if (path.includes(`${key}/create`)) {
        breadcrumbs.push(createBreadcrumbItem(breadcrumbMap[key].label, key));
        breadcrumbs.push(
          createBreadcrumbItem(
            `Criação de ${breadcrumbMap[key].label.split(" ")[breadcrumbMap[key].label.split(" ").length - 1]}`,
            "",
            "yellow",
          ),
        );
      }
    });

    return breadcrumbs;
  };

  useEffect(() => {
    setItems(getBreadcrumbs(currentPath));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath]);

  return (
    <Breadcrumb
      w="100%"
      maxW="calc(100vw - 200px)"
      spacing="8px"
      fontSize="sm"
      mb="3"
      separator={<ChevronRightIcon color="gray.500" />}
    >
      {items.map((item, index) => (
        <BreadcrumbItem key={index}>
          <BreadcrumbLink
            href={item.href === currentPath ? "#" : item.href}
            color={item.color}
          >
            {item.label}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export default BreadCrumb;
