import { Flex, Text } from "@chakra-ui/react";

import HeaderInside from "layouts/HeaderInside";

import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { IUser } from "interfaces/IUser";
import { UsersSelectModal } from "./selectUserModal";
import { ITask } from "interfaces/ITask";
import Dropdown from "components/Form/Select";
import CustomButtom from "components/Form/Button";
import InputWithLabel from "components/Form/input/InputWithLabel";
import { CollaboratorsCard } from "./colaboratorCard";
import CustomCheckBox from "components/Form/CustomCheckBox";
import DateInput from "components/DateComponents/DatePicker";

interface ITaskDataModal {
  task: ITask;
  isLoadingTasks: boolean;
  usersInProject: IUser[];
  handleSaveTaskData: (task: any) => Promise<void>;
  setSelectedTask: Dispatch<SetStateAction<ITask>>;
  setModalTaskData: Dispatch<SetStateAction<boolean>>;
}

export const ModalAddTask: React.FC<ITaskDataModal> = ({
  task,
  isLoadingTasks,
  usersInProject,
  setSelectedTask,
  handleSaveTaskData,
  setModalTaskData,
}) => {
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [collaborators, setCollaborators] = useState<IUser[]>([]);
  const [assignedToUsers, setAssignedToUsers] = useState<IUser[]>([]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = event.target;
      if (name == "manager") {
        const manager = usersInProject.find((u) => u.id == value);
        if (value == undefined || value == "Responsavel") {
          setSelectedTask({
            ...task,
            manager: {
              id: "",
              name: "",
              email: "",
            },
          });
        }
        if (manager?.id)
          setSelectedTask({
            ...task,
            manager: {
              id: manager?.id,
              name: `${manager.firstName} ${manager.lastName}}`,
              email: manager.email,
            },
          });
      } else if (name.includes("expectedHours")) {
        setSelectedTask({ ...task, [name]: Number(value) });
      } else {
        setSelectedTask({ ...task, [name]: value });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [task],
  );

  const handleToAssignCollaborator = useCallback((user: IUser) => {
    setLoadingUsers(true);
    setCollaborators((prop) => prop.filter((x) => x.id != user.id));
    setAssignedToUsers((prop) => [...prop, user]);
    setLoadingUsers(false);
  }, []);

  const handleUnassignCollaborator = useCallback(async (user: IUser) => {
    setCollaborators((prop) => [...prop, user]);
    setAssignedToUsers((prop) => prop.filter((x) => x.id != user.id));
  }, []);

  useEffect(() => {
    const allUsers: IUser[] = [];
    const usersAssigned: IUser[] = [];
    if (usersInProject?.length > 0) {
      usersInProject.forEach((u) => {
        task?.assignedToUsers?.find((x) => x.id == u.id)
          ? usersAssigned.push(u)
          : allUsers.push(u);
      });
      setAssignedToUsers(usersAssigned);
      setCollaborators(allUsers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const usersAssigned: { id: string; name: string }[] = [];
    assignedToUsers.map((u) => {
      if (u.id) usersAssigned.push({ id: u.id, name: u.firstName });
    });
    setSelectedTask({ ...task, assignedToUsers: usersAssigned });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedToUsers]);

  return (
    <>
      <HeaderInside maxH="50px">
        <Flex justify={"space-between"} align={"center"} flex="1" gap="4">
          <Text fontSize={["md"]}>Criação de task</Text>
        </Flex>
      </HeaderInside>

      <Flex
        p="4"
        flexDir={["column", "column", "row"]}
        gap="6"
        overflow={"auto"}
        pr="3"
        h="100%"
      >
        <Flex flex="1" flexDir={"column"} gap="3">
          <InputWithLabel
            label="Nome da task"
            value={task.name}
            name="name"
            onChange={(e) => handleInputChange(e)}
            required
          />
          <InputWithLabel
            label="Descrição"
            value={task.description}
            name="description"
            onChange={(e) => handleInputChange(e)}
          />

          <CustomCheckBox
            isChecked={task.isActive}
            value={task.isActive}
            name="isActive"
            onChange={(e) =>
              setSelectedTask({ ...task, isActive: e.target.checked })
            }
          >
            <Text fontWeight="normal" fontSize={["sm"]}>
              {task.isActive ? <>Task ativada</> : <>Task desativada</>}
            </Text>
          </CustomCheckBox>

          <Flex flexDir={"column"} gap="4" mt="3">
            <Text color="green1" fontSize={["md", "lg"]}>
              Estimativa
            </Text>
            <Flex gap="4" align={"center"} justify={"space-between"}>
              {/* <SingleDatePicker
                minW="100%"
                w={['100%']}
                positionCalendar="top-left"
                label="Data de início"
                name={'expectedStart'}
                value={task.expectedStart}
                handleChange={(e) => {
                  setSelectedTask({
                    ...task,
                    expectedStart: e.day
                      .toISOString()
                      .split('T')[0]
                      .split('-')
                      .reverse()
                      .join('/')
                  })
                }}
              /> */}

              <DateInput
                value={task.expectedStart}
                onChange={(day) => {
                  setSelectedTask({
                    ...task,
                    expectedStart: day,
                  });
                }}
                label="Data de início"
                name={"expectedStart"}
                // positionCalendar="top-left"
              />

              {/* <SingleDatePicker
                minW="100%"
                w={['100%']}
                positionCalendar="top-left"
                label="Data término"
                name={'expectedEnd'}
                value={task.expectedEnd}
                handleChange={(e) => {
                  setSelectedTask({
                    ...task,
                    expectedEnd: e.day
                      .toISOString()
                      .split('T')[0]
                      .split('-')
                      .reverse()
                      .join('/')
                  })
                }}
              /> */}

              <DateInput
                value={task.expectedEnd}
                onChange={(day) => {
                  setSelectedTask({
                    ...task,
                    expectedEnd: day,
                  });
                }}
                label="Data término"
                name={"expectedEnd"}
              />
            </Flex>

            <Flex gap="4" align={"center"} justify={"space-between"}>
              <InputWithLabel
                label="Total de horas"
                type="string"
                name="expectedHours"
                value={task.expectedHours}
                onChange={(e) => handleInputChange(e)}
              />
            </Flex>
          </Flex>
        </Flex>

        <Flex flex="1" flexDir={"column"} gap="3" mt="2">
          <Dropdown
            minW="100%"
            fontSize={["sm"]}
            label="Responsavel hvar"
            name="manager"
            onChange={(e) => handleInputChange(e)}
            required
          >
            <option value={undefined}>Responsavel</option>

            {usersInProject.map((item, key: number) => {
              if (task.manager.id == item.id) {
                return (
                  <option key={key} value={item.id} selected>
                    {item.firstName} {item.lastName}
                  </option>
                );
              } else {
                return (
                  <option key={key} value={item.id}>
                    {item.firstName} {item.lastName}
                  </option>
                );
              }
            })}
          </Dropdown>
          <UsersSelectModal
            loadingUser={loadingUsers}
            users={collaborators}
            handleSelectedUser={handleToAssignCollaborator}
          />
          <Flex
            flex="1"
            h="100%"
            maxH="calc(100vh - 400px)"
            gap="3"
            flexDir={"column"}
            overflow={loadingUsers ? "hidden" : "auto"}
            pr="2"
          >
            {assignedToUsers.map((item, index) => {
              return (
                <CollaboratorsCard
                  key={index}
                  user={item}
                  handleRemoveUser={handleUnassignCollaborator}
                  layout="simple"
                />
              );
            })}
          </Flex>

          <Flex align={"center"} justify={"flex-end"} mt="auto" pt="5">
            <CustomButtom
              primaryText="Cancelar"
              isLoading={isLoadingTasks}
              _hover={{
                opacity: 0.8,
              }}
              onClick={() => setModalTaskData(false)}
            />
            <CustomButtom
              primaryText="Salvar Task"
              bg="orange2"
              color="white"
              isLoading={isLoadingTasks}
              _hover={{
                opacity: 0.8,
              }}
              onClick={() => {
                handleSaveTaskData(task);
              }}
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
