import { Button } from "@chakra-ui/react";

type ICustomButtom = {
  primaryText?: string;
  [key: string]: any;
};

const CustomButtom: React.FC<ICustomButtom> = ({ primaryText, ...rest }) => {
  return (
    <Button
      colorScheme="blue"
      mr={3}
      borderColor={"orange2"}
      color="orange2"
      variant={"outline"}
      fontSize={"sm"}
      ref={undefined}
      {...rest}
    >
      {primaryText}
    </Button>
  );
};

export default CustomButtom;
