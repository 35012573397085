import { Flex, Text } from "@chakra-ui/react";
import HeaderInside from "layouts/HeaderInside";
import { VideoPlayer } from "./components/VideoPlayer";

export const Faq: React.FC = () => {
  const videosIds = [
    {
      id: "1qOudZoO7jYALfPClgo7-vLlugRqkNQRE",
      name: "Cadastro de Clientes e Projetos",
    },
    {
      id: "1FJpG2WiFl7JZNDRGAA2VD9nyeLLLg1xV",
      name: "Lançamento e Aprovação de Horas",
    },
  ];

  return (
    <>
      <HeaderInside>
        <Flex justify={"space-between"} align={"center"} flex="1" gap="4">
          <Text fontSize={["md"]}>Orientações HVAR Connect</Text>
        </Flex>
      </HeaderInside>
      <Flex
        p="5"
        align="center"
        w="100%"
        justify={["center", "center", "center", "flex-start"]}
        flexWrap="wrap"
        gap="6"
        pr="3"
        overflow={"auto"}
        maxH="calc(100vh - 300px)"
      >
        {videosIds.map((item, key) => (
          <>
            <VideoPlayer key={key} videoId={item.id} name={item.name} />
          </>
        ))}
      </Flex>
    </>
  );
};
