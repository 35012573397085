import { DeleteIcon } from "@chakra-ui/icons";
import { Flex, Img, Text } from "@chakra-ui/react";
import { ITask } from "interfaces/ITask";

interface ICardTask {
  task: any;
  handleSelectTask: (task: any) => void;
  handleDeleteTask: (task: ITask) => void;
}

export const CardTask: React.FC<ICardTask> = ({
  task,
  handleSelectTask,
  handleDeleteTask,
}) => {
  return (
    <>
      <Flex
        bg="grayBg"
        align={"center"}
        justify={"center"}
        borderRadius={"8px"}
        p="3"
        fontSize={["xs", "sm"]}
        cursor={"pointer"}
      >
        <Flex
          onClick={() => handleSelectTask(task)}
          _hover={{
            opacity: 0.6,
          }}
          w="100%"
          align={"center"}
          justify={"space-between"}
          mr="6"
        >
          <Flex flexDir={"column"}>
            <Text color="GrayText" fontSize={"xs"}>
              Nome da task
            </Text>
            <Text
              isTruncated
              maxW={["80px", "100px", "200px", "300px"]}
              minW={["80px", "100px", "200px", "300px"]}
            >
              {task.name}
            </Text>
          </Flex>
          <Flex flexDir={"column"}>
            <Text fontSize={"xs"} color="GrayText">
              Descrição
            </Text>
            <Text
              isTruncated
              maxW={["80px", "200px", "200px", "300px", "400px"]}
              minW={["80px", "200px", "200px", "300px", "400px"]}
            >
              {" "}
              {task.description}
            </Text>
          </Flex>
        </Flex>
        <Flex
          color={task.isActive ? "red" : "yallow"}
          align={"center"}
          justify={"center"}
          gap="2"
          pt="2"
          _hover={{
            color: "#aa2f2f",
          }}
          onClick={() => handleDeleteTask(task)}
          fontSize={"sm"}
        >
          <Img as={DeleteIcon} fontSize={["sm", "sm"]} />
          <Text>{task.isActive ? <>Desativar</> : <>Ativar</>}</Text>
        </Flex>
      </Flex>
    </>
  );
};
