import { TimeReports } from "entities/timeReports";
import { ITimeReports } from "interfaces/ITimeReports";
import { FirestoreRepository } from "repositories/FirestoreRepository";

export class TimeReportsService {
  constructor(
    private timeReportsRepository = new FirestoreRepository<TimeReports>(
      "time_reports",
      TimeReports.fromJson,
    ),
  ) {}

  convertToInterface(document: TimeReports): ITimeReports {
    return <ITimeReports>{
      id: document.id,
      done: document.done,
      createdBy: document.createdBy,
      updatedBy: document.updatedBy,
      createdAt: document.createdAt,
      updatedAt: document.updatedAt,
    };
  }

  convertToEntity(document: ITimeReports): TimeReports {
    return new TimeReports(
      document.id,
      document.done,
      document.createdBy,
      document.updatedBy,
      document.createdAt,
      document.updatedAt,
    );
  }

  public async postBlockTimeReports(
    doc: string,
    userId: string,
  ): Promise<void> {
    const timeReport = {
      id: doc,
      done: true,
      createdBy: userId,
      updatedBy: userId,
      createdAt: new Date(),
      updatedAt: new Date(),
    };

    await this.timeReportsRepository.createDocument(
      this.convertToEntity(timeReport),
      timeReport.id,
    );
  }

  public async updateBlockTimeReports(
    doc: ITimeReports,
    userId: string,
  ): Promise<void> {
    const timeReport = {
      id: doc.id,
      done: !doc.done,
      createdBy: doc.createdBy,
      updatedBy: userId,
      createdAt: doc.createdAt,
      updatedAt: new Date(),
    };

    await this.timeReportsRepository.updateDocument(
      this.convertToEntity(timeReport),
      timeReport.id,
    );
  }

  public async getTimeReportsToCurrentMonth(
    month: string,
  ): Promise<ITimeReports | undefined> {
    const timeReports = (await this.timeReportsRepository.getDocuments()).docs
      .map((timeReport) => this.convertToInterface(timeReport.data()))
      .filter((timeReport) => timeReport.id === month);

    return timeReports[0];
  }
}
