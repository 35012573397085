import { Flex } from "@chakra-ui/react";
import { useEffect } from "react";
import { useAuth } from "modules/auth/hooks";
import { useHistory } from "react-router-dom";

type Props = {
  children?: React.ReactNode;
};
const LoggedOut: React.FC<Props> = ({ children }) => {
  const { data } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (data?.isActive) {
      history.push("/timesheet/note");
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.email, history]);

  return (
    <>
      <Flex
        bg="grayBg"
        h="100vh"
        w="100vw"
        color="black"
        flexDir="column"
        fontSize={"xl"}
      >
        {children}
      </Flex>
    </>
  );
};

export default LoggedOut;
