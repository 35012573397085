export default class Task {
  id?: string;
  name: string;
  description?: string;
  manager: {
    id: string;
    name: string;
    email: string;
  };
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
  expectedStart?: Date;
  expectedEnd?: Date;
  expectedHours?: number;
  assignedToUsers?: {
    id: string;
    name: string;
  }[];

  constructor(
    name: string,
    manager: {
      id: string;
      name: string;
      email: string;
    },
    isActive: boolean,
    createdAt: Date,
    updatedAt: Date,
    description?: string,
    expectedStart?: Date,
    expectedEnd?: Date,
    expectedHours?: number,
    assignedToUsers?: {
      id: string;
      name: string;
    }[],
    id?: string,
  ) {
    this.name = name;
    this.manager = manager;
    this.isActive = isActive;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.description = description;
    this.expectedStart = expectedStart;
    this.expectedHours = expectedHours;
    this.expectedEnd = expectedEnd;
    this.assignedToUsers = assignedToUsers;
    this.id = id;
  }

  static fromJson(json: any, id?: string): Task {
    const newTask = new Task(
      json["name"],
      json["manager"],
      json["is_active"],
      json["created_at"],
      json["updated_at"],
      json["description"],
      json["expected_start"],
      json["expected_end"],
      json["expected_hours"],
      json["assigned_to_users"],
      id,
    );
    return newTask;
  }

  toJson(): any {
    const fields = {
      name: this.name,
      description: this.description,
      manager: this.manager,
      expected_start: this.expectedStart,
      expected_end: this.expectedEnd,
      expected_hours: this.expectedHours,
      is_active: this.isActive,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
      assigned_to_users: this.assignedToUsers,
    };

    const newObj = {};
    for (const key in fields) {
      if (fields[key] !== undefined) {
        newObj[key] = fields[key];
      }
    }

    return newObj;
  }
}
