import { TabPanel, TabPanels } from "@chakra-ui/react";
import { TabPanelsContainerProps } from "../types";

const TabPanelsContainer: React.FC<TabPanelsContainerProps> = ({ tabs }) => {
  return (
    <TabPanels py="2" px="5">
      {tabs.map((tab, index) => (
        <TabPanel py="2" px="0" key={index} height={"100%"}>
          {tab.content}
        </TabPanel>
      ))}
    </TabPanels>
  );
};

export default TabPanelsContainer;
