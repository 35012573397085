import { Sector } from "entities/Sector";
import { FirestoreRepository } from "repositories/FirestoreRepository";

export class SectorsService {
  constructor(
    private sectorsRepository = new FirestoreRepository<Sector>(
      "sectors",
      Sector.fromJson,
    ),
  ) {}

  convertToInterface(document: Sector): Sector {
    return <Sector>{
      id: document.id,
      createdAt: document.createdAt,
      description: document.description,
      name: document.name,
      updatedAt: document.updatedAt,
    };
  }

  public async getAllSectors(): Promise<Sector[]> {
    const sectors = await this.sectorsRepository.getDocuments();

    return sectors.docs.map((document) => {
      return this.convertToInterface(document.data());
    });
  }
}
