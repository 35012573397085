export function sortObjects<T>(
  array: T[],
  fieldName: keyof T,
  order: "asc" | "desc" = "asc",
): T[] {
  return array.slice().sort((a: T, b: T) => {
    const valueA = a[fieldName];
    const valueB = b[fieldName];

    // Handle missing or undefined values gracefully
    if (valueA === undefined && valueB === undefined) {
      return 0; // No sorting needed for missing values
    } else if (valueA === undefined) {
      return order === "asc" ? 1 : -1; // Move undefined values to the end on ascending sort
    } else if (valueB === undefined) {
      return order === "asc" ? -1 : 1; // Move undefined values to the beginning on descending sort
    }

    // Handle different data types (strings, numbers, etc.)
    if (typeof valueA === "string" && typeof valueB === "string") {
      return (
        valueA.localeCompare(valueB, undefined, { numeric: true }) *
        (order === "asc" ? 1 : -1)
      );
    } else if (typeof valueA === "number" && typeof valueB === "number") {
      return (valueA - valueB) * (order === "asc" ? 1 : -1);
    } else {
      // For other data types, consider custom comparison logic or throw an error
      throw new Error("Unsupported data type for sorting");
    }
  });
}
