export class TimeReports {
  id: string;
  done: boolean;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(
    id: string,
    done: boolean,
    createdBy: string,
    updatedBy: string,
    createdAt,
    updatedAt,
  ) {
    this.id = id;
    this.createdBy = createdBy;
    this.updatedBy = updatedBy;
    this.done = done;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  static fromJson(json: any, id: string): TimeReports {
    return new TimeReports(
      id,
      json["done"],
      json["createdBy"],
      json["updatedBy"],
      json["created_at"],
      json["updated_at"],
    );
  }

  toJson(): any {
    const fields = {
      id: this.id,
      done: this.done,
      created_by: this.createdBy,
      updated_by: this.updatedBy,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
    };

    const newObj = {};
    for (const key in fields) {
      if (fields[key] !== undefined) {
        newObj[key] = fields[key];
      }
    }

    return newObj;
  }
}
