import { Flex } from "@chakra-ui/react";

type HeaderInsideProps = {
  children?: React.ReactNode;
  [key: string]: any;
};

const HeaderInside: React.FC<HeaderInsideProps> = ({ children, ...rest }) => {
  return (
    <Flex
      minH="14"
      flex="1"
      bg="green1"
      gap="3"
      px="5"
      color="white"
      align={"center"}
      ref={undefined}
      {...rest}
    >
      {children}
    </Flex>
  );
};

export default HeaderInside;
